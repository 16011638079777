<template>
  <a-spin :spinning="skipLoading" :style="backCss">
    <body-layout title>
      <div v-if="isDesktop()" class="actions flex" style="flex-wrap: wrap; position: relative" slot="actions">
        <a-button type="danger" class="mr-3" @click="batchDel" v-if="false">删除</a-button>
        <a-button-group style="display: flex">
          <a-tooltip>
            <template slot="title"> 列表 </template>
            <a-button
              icon="unordered-list"
              @click="changeViewType('list')"
              :style="viewType == 'list' ? 'width:45px;z-index:9' : 'width:45px'"
              :type="viewType == 'list' ? 'primary' : ''"
              :ghost="viewType == 'list' ? true : false"
            ></a-button>
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> 日历 </template>
            <a-button
              icon="table"
              @click="changeViewType('calendar')"
              :style="viewType == 'calendar' && !isView ? 'width:45px;z-index:9' : 'width:45px'"
              :type="viewType == 'calendar' && !isView ? 'primary' : ''"
              :ghost="viewType == 'calendar' && !isView ? true : false"
            ></a-button>
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> 备忘录 </template>
            <a-button
              :icon="'calendar'"
              @click="changeViewType('calendar-table')"
              :style="viewType == 'calendar' && isView ? 'width:45px;z-index:9' : 'width:45px'"
              :type="viewType == 'calendar' && isView ? 'primary' : ''"
              :ghost="viewType == 'calendar' && isView ? true : false"
            ></a-button>
          </a-tooltip>
          <a-tooltip
            v-if="
              userInfo.positions &&
                userInfo.positions.some(p => p.name == '翻译' || p.name == '校对' || p.name == '终审')
            "
          >
            <template slot="title"> 待确认 </template>
            <a-badge :count="confirmTableTotal">
              <a-button
                :icon="'audit'"
                @click="changeViewType('confirm-list')"
                :style="viewType == 'confirm-list' ? 'width:45px;z-index:9' : 'width:45px'"
                :type="viewType == 'confirm-list' ? 'primary' : ''"
                :ghost="viewType == 'confirm-list' ? true : false"
              ></a-button>
            </a-badge>
          </a-tooltip>
        </a-button-group>
        <div
          :style="
            `position: absolute; bottom: 0; ${
              userInfo.positions &&
              userInfo.positions.some(p => p.name == '翻译' || p.name == '校对' || p.name == '终审')
                ? 'right: 200px;'
                : 'right: 165px;'
            }height:32px; width: 230px; display: flex; justify-content: end`
          "
        >
          <div
            v-if="(viewType == 'calendar' && !isView) || viewType == 'list'"
            style="margin-right:15px;line-height:32px;position: absolute;top:1px;"
            :style="{
              left:
                viewType == 'list'
                  ? !selectionRows.some(item => item.taskName != '给稿') ||
                    !selectionRows.some(item => item.taskName != '制作') ||
                    selectionRows.every(item => item.taskName == '监制确认' || item.taskName == '监制')
                    ? '-40px'
                    : '0px'
                  : !selectionRows.some(item => item.taskName != '给稿') ||
                    !selectionRows.some(item => item.taskName != '制作') ||
                    selectionRows.every(item => item.taskName == '监制确认' || item.taskName == '监制')
                  ? '0px'
                  : '43px'
            }"
          >
            <a-tooltip placement="bottom" transitionName="">
              <template slot="title">
                <div style="padding:6px 12px;font-size:12px">
                  <!-- 最终显示效果与文案不匹配，以显示效果为准 -->
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 4px;background:rgb(255 229 199);color:orange;">
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">驳回给他人的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 4px;background:#fff9b7;color:orange;">
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">拖稿的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div
                      style="height:28px;line-height:28px;padding:0 3px;background-color:rgb(255 225 225);border: 1px solid #ffa39e;color:orange;"
                    >
                      任务名称
                    </div>
                    <div style="margin-left:5px;position: relative;top: -4px;">
                      高优先级（需要在2小时内完成）<br />或截止日期在当日18点之前被驳回的任务
                    </div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 4px;background-color:#f8f8f8;color:orange;">
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">被驳回的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 4px;background-color:#fff9b7;color:green;">
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">拖稿的已完成的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 4px;background-color:#f8f8f8;color:green;">
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">常规已完成的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div
                      style="height:28px;line-height:28px;padding:0 4px;background-color:rgb(255 229 199);color:red;"
                    >
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">驳回过的可开始的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 4px;background-color:#f8f8f8;color:red;">
                      任务名称
                    </div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">常规可开始的任务</div>
                  </div>
                  <div style="display:flex;height:32px;">
                    <div style="height:28px;line-height:28px;padding:0 3px;border: 1px solid orange;">任务名称</div>
                    <div style="height:28px;line-height:28px;margin-left:5px;">被驳回过的任务</div>
                  </div>
                  <div style="display:flex;margin-top:10px">
                    <div style="margin-left: 5px;">
                      <span class="circular" style="background: green;margin-left:3px"></span>
                      <span style="margin-left: 3px;">已完成</span>
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="circular" style="background: red;margin-left:3px"></span>
                      <span style="margin-left: 3px;">可开始</span>
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="circular" style="background: gray;margin-left:3px"></span>
                      <span style="margin-left: 3px;">等待中</span>
                    </div>
                    <div style="margin-left: 5px;">
                      <span class="circular" style="background: orange;margin-left:3px"></span>
                      <span style="margin-left: 3px;">驳回</span>
                    </div>
                  </div>
                </div>
              </template>
              <a-icon type="question-circle" style="font-size:16px;" />
            </a-tooltip>
          </div>
          <a-dropdown v-if="!isView && viewType != 'list' && viewType != 'confirm-list'" :placement="'bottomCenter'">
            <a-button
              icon="check-square"
              style="
                margin-left: 10px;
                width: 45px;
                border-right: none;
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
              "
            ></a-button>
            <a-menu slot="overlay">
              <a-menu-item
                v-has="'myTask:batchDrop'"
                :disabled="isCalendarCancel"
                @click="changeDropSelect"
                v-if="viewType == 'calendar'"
                >批量拖拽</a-menu-item
              >
              <a-menu-item :disabled="isCalendarDropCancel" @click="changeSelect" v-if="viewType == 'calendar'"
                >批量完成/下载</a-menu-item
              >
            </a-menu>
          </a-dropdown>
          <a-dropdown
            :disabled="
              !selectionRows.length ||
                selectionRows.some(item => item.taskName != selectionRows[0].taskName) ||
                !(
                  ((viewType == 'calendar' && !isView) || viewType == 'list') &&
                  (!selectionRows.some(item => item.taskName != '给稿') ||
                    selectionRows.every(
                      item =>
                        item.taskName == '制作' &&
                        item.systemTypes &&
                        item.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM')
                    ) ||
                    selectionRows.every(
                      item =>
                        (item.taskName == '监制确认' || item.taskName == '监制' || item.taskName == '交稿') &&
                        item.applyReview
                    ) ||
                    selectionRows.every(
                      item =>
                        (item.taskName == '制作' ||
                          item.taskName == '制作修改' ||
                          item.stageName == '监制' ||
                          item.stageName == '监制确认' ||
                          item.stageName.indexOf('建模') > -1 ||
                          item.stageName.indexOf('精草') > -1 ||
                          item.stageName.indexOf('勾线') > -1 ||
                          item.stageName.indexOf('上色') > -1 ||
                          item.stageName.indexOf('后期') > -1) &&
                        item.applyReview
                    ))
                )
            "
            v-if="(viewType != 'calendar' || !isView) && viewType != 'confirm-list'"
            :placement="'bottomCenter'"
          >
            <a-button
              icon="download"
              :style="{
                width: '45px',
                borderRight: 'none',
                borderTopRightRadius: 'unset',
                borderBottomRightRadius: 'unset',
                borderTopLeftRadius: viewType == 'list' ? '' : 'unset',
                borderBottomLeftRadius: viewType == 'list' ? '' : 'unset'
              }"
            ></a-button>
            <a-menu slot="overlay">
              <a-menu-item
                v-has="'myTasks::button:admin'"
                @click="openManuscriptList"
                v-if="!selectionRows.some(item => item.taskName != '给稿')"
                >上传原稿</a-menu-item
              >
              <a-menu-item
                v-has="'myTasks::button:admin'"
                @click="openSumbmitList"
                v-if="
                  selectionRows.every(
                    item =>
                      (item.taskName == '制作' ||
                        item.taskName == '制作修改' ||
                        item.stageName == '监制' ||
                        item.stageName == '监制确认' ||
                        item.stageName.indexOf('建模') > -1 ||
                        item.stageName.indexOf('精草') > -1 ||
                        item.stageName.indexOf('勾线') > -1 ||
                        item.stageName.indexOf('上色') > -1 ||
                        item.stageName.indexOf('后期') > -1) &&
                      item.applyReview &&
                      ((item.taskStatus != '等待' && item.taskStatus != '驳回' && item.taskStatus != '已完成') ||
                        (item.taskStatus == '驳回' && item.rejectTaskId))
                  )
                "
                >上传完成稿</a-menu-item
              >
              <a-menu-item
                v-has="'myTasks::button:admin'"
                @click="downloadMake"
                v-if="
                  selectionRows.every(
                    item =>
                      item.taskName == '制作' &&
                      item.systemTypes &&
                      item.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM')
                  )
                "
                >下载制作稿</a-menu-item
              >
              <a-menu-item
                v-has="'myTasks::button:admin'"
                @click="downloadOver(false)"
                v-if="
                  selectionRows.every(
                    item =>
                      (item.taskName == '监制确认' ||
                        item.stageName == '勾线终审' ||
                        item.stageName == '上色终审' ||
                        item.taskName == '监制' ||
                        item.taskName == '交稿') &&
                      item.applyReview
                  )
                "
                >下载终稿</a-menu-item
              >
              <a-menu-item
                v-has="'myTasks::button:admin'"
                @click="downloadOver(true)"
                v-if="
                  selectionRows.every(
                    item =>
                      (item.taskName == '监制确认' || item.taskName == '监制') &&
                      item.applyReview &&
                      item.departName.indexOf('日语部') > -1
                  )
                "
                >下载切片</a-menu-item
              >
            </a-menu>
          </a-dropdown>
          <a-button
            @click="sortTable"
            style="width:45px;border-right:none;border-radius: unset;"
            v-if="viewType == 'list'"
            @mouseover="color1 = color"
            @mouseout="color1 = '#4a4a4a'"
            ><sort-two style="position: relative;top: 2px;" theme="outline" size="18" :fill="color1" :strokeWidth="3"
          /></a-button>
          <a-button
            icon="edit"
            :disabled="isCalendarDropCancel || !this.selectionRows.length"
            @click="openMonthList"
            v-if="viewType == 'list'"
            style="width: 45px; border-right: none; border-radius: unset"
          ></a-button>
          <!--           <a-dropdown
            :visible="visible"
            :disabled="selectKeys.length == 0"
            @visibleChange="visibleChange"
            :trigger="['click']"
            v-if="viewType == 'calendar' && !isView"
          >
            <a-button
              @click.stop="getRejectStages"
              style="border-top-left-radius: unset;border-bottom-left-radius: unset;"
              >完成状态</a-button
            >
            <a-menu slot="overlay" v-if="selectKeys.length > 0">
              <a-menu-item @click="handleTaskFine()">
                <div>
                  <span class="circular" style="background: green"></span>
                  <span>已完成</span>
                </div>
              </a-menu-item>
              <a-menu-item
                v-if="
                  selectKeys.some(item => item.taskStatus !== '驳回') &&
                    selectKeys.some(item => item.stageName !== '给稿') &&
                    rejectStages.length > 0
                "
              >
                <a-dropdown :trigger="['click']">
                  <div>
                    <span class="circular" style="background: orange"></span>
                    <span>驳回</span>
                  </div>
                  <div
                    slot="overlay"
                    style=" position: absolute; top: -3.6vh; left: 4.2vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                  >
                    <a-menu v-for="item in rejectStages" :key="item.stageId">
                      <a-menu-item style="height:30px;" @click="handleTasks(item.stageId, 'MIDDLE')">
                        <a-dropdown>
                          <div style="line-height:30px;">{{ item.stageName }}</div>
                          <div
                            slot="overlay"
                            style=" position: absolute; top: -4vh; left: 2.6vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                          >
                            <a-menu>
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'HIGH')"
                                >高</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'MIDDLE')"
                                >普通</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'LOW')"
                                >低</a-menu-item
                              >
                            </a-menu>
                          </div>
                        </a-dropdown>
                      </a-menu-item>
                    </a-menu>
                  </div>
                </a-dropdown>
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->
          <a-dropdown
            :visible="visible"
            v-if="viewType == 'calendar' && !isView"
            :disabled="
              selectKeys.length == 0 ||
                (selectKeys.length &&
                  (selectKeys.some((item, index) => {
                    return index && item.chapterId == selectKeys[0].chapterId
                  }) ||
                    selectKeys.some((item, index) => {
                      return !item.rejectTaskId && item.taskStatus === '驳回'
                    }) ||
                    selectKeys.some(item => {
                      return item.taskStatus === '等待'
                    })))
            "
            @visibleChange="visibleChange"
            :trigger="['click']"
          >
            <a-button
              style="border-top-left-radius: unset; border-bottom-left-radius: unset; margin-right: 12px"
              @click.stop="getRejectStages"
              >完成状态</a-button
            >
            <a-menu slot="overlay" v-if="selectedRowKeys.length || selectKeys.length">
              <a-menu-item
                @click="handleTaskFine(false)"
                v-if="
                  selectKeys.every(item => item.taskStatus !== '已完成') &&
                    selectKeys.every(item => item.stageName != '甲方反馈')
                "
              >
                <div>
                  <span class="circular" style="background: green; margin-left: 3px"></span>
                  <span style="margin-left: 5px">已完成</span>
                </div>
              </a-menu-item>
              <a-menu-item
                @click="handleTaskStart('calendar')"
                v-if="selectKeys.every(item => item.taskStatus == '已完成') && (isTongChou || isAdmin)"
              >
                <div>
                  <span class="circular" style="background: red; margin-left: 3px"></span>
                  <span style="margin-left: 5px">可开始</span>
                </div>
              </a-menu-item>
              <a-menu-item
                @click="handleTaskFine(false)"
                class="status-item"
                :disabled="selectKeys.some(item => item.hasFeedback)"
                v-if="
                  selectKeys.every(item => item.taskStatus !== '已完成') &&
                    selectKeys.every(item => item.stageName == '甲方反馈')
                "
              >
                <div style="texe-align: center; padding-left: 10px">无反馈</div>
              </a-menu-item>
              <a-menu-item
                @click="() => (this.rejectShow = true)"
                class="status-item"
                v-if="
                  selectKeys.every(item => item.taskStatus !== '已完成') &&
                    selectKeys.every(item => item.stageName == '甲方反馈') &&
                    !rejectShow
                "
              >
                <div style="texe-align: center; padding-left: 10px">有反馈</div>
              </a-menu-item>
              <a-menu-item
                @click="() => handleTaskFine(true)"
                class="status-item"
                v-if="
                  selectKeys.every(item => item.taskStatus !== '已完成') &&
                    selectKeys.every(item => item.stageName == '甲方反馈') &&
                    rejectShow
                "
              >
                <div class="circular" style="background: green; margin-left: 3px"></div>
                <span style="margin-left: 5px">已完成</span>
              </a-menu-item>
              <template
                v-if="
                  selectKeys.every(item => item.taskStatus !== '已完成') &&
                    selectKeys.every(item => item.taskStatus !== '驳回') &&
                    selectKeys.every(item => item.stageName !== '给稿') &&
                    rejectStages.length > 0 &&
                    rejectShow
                "
              >
                <a-menu-item v-if="rejectStages.length > 1" v-for="(item, idx) in rejectStages" :key="idx">
                  <a-checkbox
                    v-model="item.checked"
                    @change="
                      e => {
                        onChange(e, item)
                      }
                    "
                  >
                    <a-dropdown :trigger="['hover']">
                      <div style="line-height: 30px">驳回-{{ item.stageName }}</div>
                      <div
                        slot="overlay"
                        style="position: absolute; top: -4vh; left: 4.5vw; box-shadow: 0 2px 8px rgb(0 0 0 / 15%)"
                      >
                        <a-menu>
                          <a-menu-item
                            style="height: 30px; line-height: 30px"
                            @click="priorityChange(item, '高')"
                            key="HIGH"
                            >高
                          </a-menu-item>
                          <a-menu-item
                            style="height: 30px; line-height: 30px"
                            key="MIDDLE"
                            @click="priorityChange(item, '普通')"
                            >普通
                          </a-menu-item>
                          <a-menu-item
                            style="height: 30px; line-height: 30px"
                            @click="priorityChange(item, '低')"
                            key="LOW"
                            >低
                          </a-menu-item>
                        </a-menu>
                      </div>
                    </a-dropdown>
                  </a-checkbox>
                </a-menu-item>

                <a-sub-menu v-else v-for="(item, idx) in rejectStages" :key="'submenu' + idx">
                  <div
                    slot="title"
                    style="display: flex; align-items: center; line-height: 30px; min-width: 100%"
                    @click="priorityChange(item, '普通')"
                  >
                    <div class="statusPointer" :style="{ background: 'orange' }"></div>
                    <div style="margin-left: 2px">&nbsp;驳回-{{ item.stageName }}</div>
                  </div>
                  <!-- <div slot="overlay"
                style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"> -->

                  <a-menu-item style="line-height: 30px" @click="priorityChange(item, '高')" key="HIGH"
                    >高
                  </a-menu-item>
                  <a-menu-item style="line-height: 30px" key="MIDDLE" @click="priorityChange(item, '普通')"
                    >普通
                  </a-menu-item>
                  <a-menu-item style="line-height: 30px" @click="priorityChange(item, '低')" key="LOW">低 </a-menu-item>
                </a-sub-menu>
              </template>
              <a-menu-item
                v-if="
                  rejectStages.length > 1 &&
                    selectKeys.every(item => item.taskStatus !== '已完成') &&
                    selectKeys.every(item => item.taskStatus !== '驳回') &&
                    rejectShow
                "
                style="background-color: #fff !important"
              >
                <div class="task-btn">
                  <div
                    @click="
                      () => {
                        this.visible = !this.visible
                      }
                    "
                    style="width: 50%"
                  >
                    取消
                  </div>
                  <div :style="`color:${color};width:50%`" @click="submitTask">确定</div>
                </div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <!--           <a-dropdown
            :visible="visible"
            :disabled="selectedRowKeys.length == 0"
            @visibleChange="visibleChange"
            :trigger="['click']"
            v-if="viewType == 'list'"
          >
            <a-button
              style="border-top-left-radius: unset;border-bottom-left-radius: unset;"
              @click.stop="getRejectStages"
              >完成状态</a-button
            >
            <a-menu slot="overlay" v-if="selectedRowKeys.length > 0">
              <a-menu-item @click="handleTaskFine()">
                <div>
                  <span class="circular" style="background: green"></span>
                  <span>已完成</span>
                </div>
              </a-menu-item>
              <a-menu-item
                v-if="
                  selectionRows.some(item => getTaskStatus(item.taskStatus) !== '驳回') &&
                    selectionRows.some(item => item.stageName !== '给稿') &&
                    rejectStages.length > 0
                "
              >
                <a-dropdown :trigger="['click']">
                  <div>
                    <span class="circular" style="background: orange"></span>
                    <span>驳回</span>
                  </div>
                  <div
                    slot="overlay"
                    style=" position: absolute; top: -3.6vh; left: 4.2vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                  >
                    <a-menu v-for="item in rejectStages" :key="item.stageId">
                      <a-menu-item style="height:30px;" @click="handleTasks(item.stageId, 'MIDDLE')">
                        <a-dropdown>
                          <div style="line-height:30px;">{{ item.stageName }}</div>
                          <div
                            slot="overlay"
                            style=" position: absolute; top: -4vh; left: 2.6vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                          >
                            <a-menu>
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'HIGH')"
                                >高</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'MIDDLE')"
                                >普通</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'LOW')"
                                >低</a-menu-item
                              >
                            </a-menu>
                          </div>
                        </a-dropdown>
                      </a-menu-item>
                    </a-menu>
                  </div>
                </a-dropdown>
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->
          <a-dropdown
            :visible="visible"
            v-if="viewType == 'list'"
            :disabled="
              selectionRows.length == 0 ||
                (selectionRows.length &&
                  (selectionRows.some((item, index) => {
                    return index && item.chapterId == selectionRows[0].chapterId
                  }) ||
                    selectionRows.some((item, index) => {
                      return !item.rejectTaskId && item.taskStatus === '驳回'
                    }) ||
                    selectionRows.some(item => {
                      return item.taskStatus === '等待'
                    })))
            "
            @visibleChange="visibleChange"
            :trigger="['click']"
          >
            <a-button
              style="border-top-left-radius: unset; border-bottom-left-radius: unset; margin-right: 12px"
              @click.stop="getRejectStages"
              >完成状态</a-button
            >
            <a-menu slot="overlay" v-if="selectionRows.length">
              <a-menu-item
                @click="handleTaskFine(false)"
                v-if="
                  selectionRows.some(item => item.taskStatus !== '已完成') &&
                    selectionRows.every(item => item.stageName != '甲方反馈')
                "
              >
                <div>
                  <span class="circular" style="background: green; margin-left: 3px"></span>
                  <span style="margin-left: 5px">已完成</span>
                </div>
              </a-menu-item>
              <a-menu-item
                @click="handleTaskStart('list')"
                v-if="selectKeys.every(item => item.taskStatus == '已完成') && (isTongChou || isAdmin)"
              >
                <div>
                  <span class="circular" style="background: red; margin-left: 3px"></span>
                  <span style="margin-left: 5px">可开始</span>
                </div>
              </a-menu-item>
              <a-menu-item
                @click="handleTaskFine(false)"
                class="status-item"
                :disabled="selectionRows.some(item => item.hasFeedback)"
                v-if="
                  selectionRows.every(item => item.taskStatus !== '已完成') &&
                    selectionRows.every(item => item.stageName == '甲方反馈')
                "
              >
                <div style="texe-align: center; padding-left: 10px">无反馈</div>
              </a-menu-item>
              <a-menu-item
                @click="() => (this.rejectShow = true)"
                class="status-item"
                v-if="
                  selectionRows.every(item => item.taskStatus !== '已完成') &&
                    selectionRows.every(item => item.stageName == '甲方反馈') &&
                    !rejectShow
                "
              >
                <div style="texe-align: center; padding-left: 10px">有反馈</div>
              </a-menu-item>
              <a-menu-item
                @click="() => handleTaskFine(true)"
                class="status-item"
                v-if="
                  selectionRows.every(item => item.taskStatus !== '已完成') &&
                    selectionRows.every(item => item.stageName == '甲方反馈') &&
                    rejectShow
                "
              >
                <div class="circular" style="background: green; margin-left: 3px"></div>
                <span style="margin-left: 5px">已完成</span>
              </a-menu-item>
              <template
                v-if="
                  selectionRows.every(item => item.taskStatus !== '已完成') &&
                    selectionRows.every(item => item.taskStatus !== '驳回') &&
                    selectionRows.every(item => item.stageName !== '给稿') &&
                    rejectStages.length > 0 &&
                    rejectShow
                "
              >
                <template v-if="rejectStages.length > 1">
                  <a-menu-item v-for="(item, idx) in rejectStages" :key="idx">
                    <a-checkbox
                      v-model="item.checked"
                      @change="
                        e => {
                          onChange(e, item)
                        }
                      "
                    >
                      <a-dropdown :trigger="['hover']">
                        <div style="line-height: 30px">驳回-{{ item.stageName }}</div>
                        <div
                          slot="overlay"
                          style="position: absolute; top: -4vh; left: 4.5vw; box-shadow: 0 2px 8px rgb(0 0 0 / 15%)"
                        >
                          <a-menu>
                            <a-menu-item
                              style="height: 30px; line-height: 30px"
                              @click="priorityChange(item, '高')"
                              key="HIGH"
                              >高
                            </a-menu-item>
                            <a-menu-item
                              style="height: 30px; line-height: 30px"
                              key="MIDDLE"
                              @click="priorityChange(item, '普通')"
                              >普通
                            </a-menu-item>
                            <a-menu-item
                              style="height: 30px; line-height: 30px"
                              @click="priorityChange(item, '低')"
                              key="LOW"
                              >低
                            </a-menu-item>
                          </a-menu>
                        </div>
                      </a-dropdown>
                    </a-checkbox>
                  </a-menu-item>
                </template>
                <template v-else>
                  <a-sub-menu v-for="(item, idx) in rejectStages" :key="'submenu' + idx">
                    <div
                      slot="title"
                      style="display: flex; align-items: center; line-height: 30px; min-width: 100%"
                      @click="priorityChange(item, '普通')"
                    >
                      <div class="statusPointer" :style="{ background: 'orange' }"></div>
                      <div style="margin-left: 2px">&nbsp;驳回-{{ item.stageName }}</div>
                    </div>
                    <!-- <div slot="overlay"
                style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"> -->

                    <a-menu-item style="line-height: 30px" @click="priorityChange(item, '高')" key="HIGH"
                      >高
                    </a-menu-item>
                    <a-menu-item style="line-height: 30px" key="MIDDLE" @click="priorityChange(item, '普通')"
                      >普通
                    </a-menu-item>
                    <a-menu-item style="line-height: 30px" @click="priorityChange(item, '低')" key="LOW"
                      >低
                    </a-menu-item>
                  </a-sub-menu>
                </template>
              </template>
              <a-menu-item
                v-if="
                  rejectStages.length > 1 &&
                    selectionRows.every(item => item.taskStatus !== '已完成') &&
                    selectionRows.every(item => item.taskStatus !== '驳回') &&
                    rejectShow
                "
                style="background-color: #fff !important"
              >
                <div class="task-btn">
                  <div
                    @click="
                      () => {
                        this.visible = !this.visible
                      }
                    "
                    style="width: 50%"
                  >
                    取消
                  </div>
                  <div :style="`color:${color};width:50%`" @click="submitTask">确定</div>
                </div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
      <div v-if="viewType == 'calendar'" style="width: 0; height: 0; opacity: 0">
        <div v-if="viewType != 'list' && (checkedFuncList.indexOf('排期') > -1 || funcShow)" class="work-left">
          <div v-if="!workDayValueShow">
            <span class="work-day">通常可工作日：</span>
            <span class="work-day-value">
              <span @click="workChangeEdit('day')" class="work-day-value">
                <span
                  v-for="(item, index) in Array.from(workDays || []).sort((a, b) => {
                    return a - b
                  })"
                  :key="item"
                >
                  {{
                    item == 1
                      ? '周一'
                      : item == 2
                      ? '周二'
                      : item == 3
                      ? '周三'
                      : item == 4
                      ? '周四'
                      : item == 5
                      ? '周五'
                      : item == 6
                      ? '周六'
                      : item == 7
                      ? '周日'
                      : item == -1
                      ? '跳过中国法定节假日'
                      : ''
                  }}{{ index == workDays.length - 1 ? '' : '，' }}
                </span>
              </span>
              <span v-if="!workDayNumValueShow">
                <span @click="workChangeEdit('num')" class="work-day-num">可安排工作数量</span>
              </span>
            </span>
          </div>
          <!-- 
          <a-select
            v-else
            style="width: 416px"
            ref="workSelect"
            mode="multiple"
            placeholder="通常每周可工作日"
            v-model="workDays"
            @change="workDaysChange"
            @blur="workEdit"
          >
            <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
            <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
            <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
            <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
            <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
            <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
            <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
            <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
          </a-select> -->
          <a-input-number
            v-else
            :min="0"
            ref="workInput"
            style="width: 200px; margin-left: 15px"
            placeholder="工作日可安排数量"
            v-model="workdayAvailableCount"
            @blur="workEdit"
          />
        </div>
      </div>
      <div v-if="viewType == 'calendar'" style="width: 0; height: 0; opacity: 0">
        <div v-if="viewType != 'list' && (checkedFuncList.indexOf('排期') > -1 || funcShow)" class="work-right">
          <a-tooltip overlayClassName="work-tooltip" placement="top" style="margin-left: 5px">
            <template slot="title">
              <span>{{ !isSleep ? '取消请假' : '请假' }}</span>
            </template>
            <span
              class="iconfont icon-shangban1 icon"
              :style="getWorkStyle()"
              @click="isHoliDay && isSleep ? () => {} : setVacation(!isSleep ? '0' : 'HOLIDAY')"
            ></span>
          </a-tooltip>
          <a-tooltip overlayClassName="work-tooltip" placement="top" style="margin-left: 15px">
            <template slot="title">
              <span>工作</span>
            </template>
            <span
              class="iconfont icon-shangban icon"
              :style="isWorkDay ? 'color:rgba(0,0,0,0.25);cursor:no-drop;' : ''"
              @click="isWorkDay ? () => {} : setVacation('WORK')"
            ></span>
          </a-tooltip>
        </div>
      </div>
      <div v-if="isDesktop()" class="table-page-search-wrapper" slot="search" v-has="'mytask:list:button:search_user'">
        <a-select
          v-if="
            viewType != 'list' &&
              viewType != 'confirm-list' &&
              (isAdmin || (userInfo.roles && userInfo.roles.some(item => item.roleCode == 'zhuanjia')))
          "
          mode="multiple"
          showArrow
          :not-found-content="null"
          placeholder="请输入人员昵称"
          option-filter-prop="label"
          @search="userSearch"
          :maxTagCount="3"
          v-model="userIds"
          style="width: 380px; margin-right: 15px"
          @change="searchQueryForm"
        >
          <span slot="suffixIcon">
            <a-popover
              placement="bottom"
              transitionName=""
              v-model="popoverVis"
              overlayClassName="filter-pop filter-pop-task"
              @click="getFilterTypes"
              trigger="click"
            >
              <template slot="content">
                <a-spin :spinning="filterLoading">
                  <a-collapse v-model="activeKey" :bordered="false">
                    <template #expandIcon="props">
                      <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                    </template>
                    <a-collapse-panel key="1" header="我的收藏" style="padding-left: 25px">
                      <p v-for="(item, index) in filterList" :key="index">
                        <span class="star-span">
                          <a-icon @click="starClick(item)" :style="'color:' + color" type="star" theme="filled" />
                        </span>
                        <span class="filter-push-span" style="margin-left: 3px" @click="filterListPush(item)">{{
                          item.name
                        }}</span>
                      </p>
                    </a-collapse-panel>
                  </a-collapse>
                  <div
                    class="filter-div"
                    style="padding-left: 25px; padding-bottom: 0; border-bottom: 1px solid #d9d9d9; font-size: 14px"
                  >
                    <a-row style="padding-left: 17px" v-has="'myTasks::button:search'">
                      <a-col :span="6">
                        <span class="filter-label">他人日历：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择/输入人名"
                          @search="userSearch"
                          v-model="skipUserId"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                          @change="toOtherCalender"
                        >
                          <a-select-option v-for="user in searchUsers" :label="user.userName" :key="user.userId">
                            <div
                              style="
                                width: 100%;
                                line-height: 30px;
                                display: inline-block;
                                color: rgba(0, 0, 0, 0.65);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                              target="_blank"
                            >
                              <img style="width: 30px; height: 30px; border-radius: 50%" :src="user.avatar" alt />
                              <div style="width: 100px">{{ user.userName }}</div>
                            </div>
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px" v-has="'myTasks::button:search'">
                      <a-col :span="6">
                        <span class="filter-label">人员昵称：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          v-if="!isSkip"
                          mode="multiple"
                          showArrow
                          :not-found-content="null"
                          placeholder="请选择/输入人名"
                          @search="userSearch"
                          option-filter-prop="label"
                          :maxTagCount="1"
                          v-model="userIds"
                          style="width: 200px"
                        >
                          <a-select-option :label="user.userName" v-for="user in searchUsers" :key="user.userId">
                            {{ user.userName }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">阶段：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          mode="multiple"
                          showArrow
                          placeholder="选择阶段"
                          :maxTagCount="1"
                          v-model="stageIds"
                          style="width: 200px"
                        >
                          <a-select-option v-for="stage in filterStageList" :key="stage.stageId">
                            {{ stage.stageName }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">平台：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          mode="multiple"
                          showArrow
                          placeholder="选择平台"
                          :maxTagCount="1"
                          v-model="platformIds"
                          style="width: 200px"
                        >
                          <a-select-option v-for="stage in filterPlatformList" :key="stage.platformId">
                            {{ stage.platformName }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">作品名称：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-input placeholder="作品名称" v-model="productionName" style="width: 200px" />
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">作品序号：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-input-number
                          placeholder="作品序号"
                          :min="0"
                          v-model="productSequenceNo"
                          style="width: 200px"
                        />
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">完成状态：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择完成状态"
                          mode="multiple"
                          :maxTagCount="1"
                          v-model="selTaskStatus"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                        >
                          <a-select-option value="FINISHED">已完成</a-select-option>
                          <a-select-option value="STARTED">可开始</a-select-option>
                          <a-select-option value="WAITING">等待中</a-select-option>
                          <a-select-option value="REJECTING">驳回</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">备注：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          showSearch
                          showArrow
                          placeholder="请选择"
                          v-model="hasNoteStatus"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                        >
                          <a-select-option value="2">全部</a-select-option>
                          <a-select-option value="1">有</a-select-option>
                          <a-select-option value="0">无</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">完成情况：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px; line-height: 32px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择完成情况"
                          mode="multiple"
                          :maxTagCount="1"
                          v-model="finishStatus"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                        >
                          <a-select-option value="ADVANCE">提前</a-select-option>
                          <a-select-option value="ON_TIME">按时</a-select-option>
                          <a-select-option value="DELAY">拖稿</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                  </div>
                  <div
                    class="filter-div"
                    style="
                      display: flex;
                      justify-content: space-between;
                      min-width: 190px;
                      font-size: 14px;
                      padding-left: 38px;
                      padding-right: 42px;
                    "
                  >
                    <a-popconfirm
                      overlayClassName="filter-pop-fileter-name"
                      ok-text="确认"
                      placement="bottomLeft"
                      cancel-text="取消"
                      @confirm="addFilter"
                    >
                      <span slot="icon"></span>
                      <span slot="title">
                        <a-input placeholder="请输入收藏名称" v-model="filterName" style="width: 180px" />
                      </span>
                      <span class="add-filter">+ 收藏当前条件</span>
                    </a-popconfirm>
                    <div>
                      <button
                        class="add-filter-btn"
                        style="color: rgba(0, 0, 0, 0.65); margin-right: 5px"
                        @click="removeFilter"
                      >
                        重置
                      </button>
                      <button
                        class="add-filter-btn"
                        :style="'color: #fff;background-color:' + color"
                        @click="searchAndSaveQueryForm"
                      >
                        搜索
                      </button>
                    </div>
                  </div>
                </a-spin>
              </template>
              <span
                class="search-user-span"
                :style="
                  userIds.length ||
                  stageIds.length ||
                  platformIds.length ||
                  productionName ||
                  hasNoteStatus ||
                  selTaskStatus.length ||
                  finishStatus.length
                    ? `color:${color}`
                    : ''
                "
                @click.stop="openPop"
                >高级搜索</span
              >
            </a-popover>
            <a-icon
              class="search-user"
              @click.stop="searchQueryForm"
              style="margin-left: 7px; font-size: 14px"
              type="search"
            />
          </span>
          <a-select-option :label="user.userName" v-for="user in searchUsers" :key="user.userId">
            {{ user.userName }}
          </a-select-option>
        </a-select>
        <a-input
          v-else-if="
            viewType != 'list' &&
              viewType != 'confirm-list' &&
              !isAdmin &&
              userInfo.roles &&
              userInfo.roles.every(item => item.roleCode != 'zhuanjia')
          "
          @change="searchQueryForm"
          @pressEnter="searchQueryForm"
          placeholder="请输入作品名称"
          v-model="productionName"
          style="width: 380px; margin-right: 15px"
        >
          <span slot="suffix">
            <a-popover
              placement="bottom"
              v-model="popoverVis"
              transitionName=""
              overlayClassName="filter-pop filter-pop-task"
              @click="getFilterTypes"
              trigger="click"
            >
              <template slot="content">
                <a-spin :spinning="filterLoading">
                  <a-collapse v-model="activeKey" :bordered="false">
                    <template #expandIcon="props">
                      <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                    </template>
                    <a-collapse-panel key="1" header="我的收藏" style="padding-left: 25px">
                      <p v-for="(item, index) in filterList" :key="index">
                        <span class="star-span">
                          <a-icon @click="starClick(item)" :style="'color:' + color" type="star" theme="filled" />
                        </span>
                        <span class="filter-push-span" style="margin-left: 3px" @click="filterListPush(item)">{{
                          item.name
                        }}</span>
                      </p>
                    </a-collapse-panel>
                  </a-collapse>
                  <div
                    class="filter-div"
                    style="padding-left: 25px; padding-bottom: 0; border-bottom: 1px solid #d9d9d9; font-size: 14px"
                  >
                    <a-row style="padding-left: 17px" v-has="'myTasks::button:search'">
                      <a-col :span="6">
                        <span class="filter-label">他人日历：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择/输入人名"
                          v-model="skipUserId"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                          @change="toOtherCalender"
                        >
                          <a-select-option v-for="user in allUsers" :label="user.userName" :key="user.userId">
                            <div
                              style="
                                width: 100%;
                                line-height: 30px;
                                display: inline-block;
                                color: rgba(0, 0, 0, 0.65);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                              target="_blank"
                            >
                              <img style="width: 30px; height: 30px; border-radius: 50%" :src="user.avatar" alt />
                              <div style="width: 100px">{{ user.userName }}</div>
                            </div>
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px" v-has="'myTasks::button:search'">
                      <a-col :span="6">
                        <span class="filter-label">人员昵称：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          v-if="!isSkip"
                          mode="multiple"
                          showArrow
                          :not-found-content="null"
                          placeholder="请选择/输入人名"
                          @search="userSearch"
                          option-filter-prop="label"
                          :maxTagCount="1"
                          v-model="userIds"
                          style="width: 200px"
                        >
                          <a-select-option :label="user.userName" v-for="user in searchUsers" :key="user.userId">
                            {{ user.userName }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">阶段：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          mode="multiple"
                          showArrow
                          placeholder="选择阶段"
                          :maxTagCount="1"
                          v-model="stageIds"
                          style="width: 200px"
                        >
                          <a-select-option v-for="stage in filterStageList" :key="stage.stageId">
                            {{ stage.stageName }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">平台：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          mode="multiple"
                          showArrow
                          placeholder="选择阶段"
                          :maxTagCount="1"
                          v-model="platformIds"
                          style="width: 200px"
                        >
                          <a-select-option v-for="stage in filterPlatformList" :key="stage.platformId">
                            {{ stage.platformName }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">作品名称：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-input placeholder="作品名称" v-model="productionName" style="width: 200px" />
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">作品序号：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-input-number
                          placeholder="作品序号"
                          :min="0"
                          v-model="productSequenceNo"
                          style="width: 200px"
                        />
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">完成状态：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择完成状态"
                          mode="multiple"
                          :maxTagCount="1"
                          v-model="selTaskStatus"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                        >
                          <a-select-option value="FINISHED">已完成</a-select-option>
                          <a-select-option value="STARTED">可开始</a-select-option>
                          <a-select-option value="WAITING">等待中</a-select-option>
                          <a-select-option value="REJECTING">驳回</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">备注：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择"
                          v-model="hasNoteStatus"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                        >
                          <a-select-option value="2">全部</a-select-option>
                          <a-select-option value="1">有</a-select-option>
                          <a-select-option value="0">无</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left: 17px">
                      <a-col :span="6">
                        <span class="filter-label">完成情况：</span>
                      </a-col>
                      <a-col :span="18" style="padding-left: 15px; line-height: 32px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择完成情况"
                          mode="multiple"
                          :maxTagCount="1"
                          v-model="finishStatus"
                          option-filter-prop="label"
                          style="width: 200px; position: relative"
                        >
                          <a-select-option value="ADVANCE">提前</a-select-option>
                          <a-select-option value="ON_TIME">按时</a-select-option>
                          <a-select-option value="DELAY">拖稿</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                  </div>
                  <div
                    class="filter-div"
                    style="
                      display: flex;
                      justify-content: space-between;
                      min-width: 190px;
                      font-size: 14px;
                      padding-left: 38px;
                      padding-right: 42px;
                    "
                  >
                    <a-popconfirm
                      overlayClassName="filter-pop-fileter-name"
                      ok-text="确认"
                      placement="bottomLeft"
                      cancel-text="取消"
                      @confirm="addFilter"
                    >
                      <span slot="icon"></span>
                      <span slot="title">
                        <a-input placeholder="请输入收藏名称" v-model="filterName" style="width: 180px" />
                      </span>
                      <span class="add-filter">+ 收藏当前条件</span>
                    </a-popconfirm>
                    <div>
                      <button
                        class="add-filter-btn"
                        style="color: rgba(0, 0, 0, 0.65); margin-right: 5px"
                        @click="removeFilter"
                      >
                        重置
                      </button>
                      <button
                        class="add-filter-btn"
                        :style="'color: #fff;background-color:' + color"
                        @click="searchAndSaveQueryForm"
                      >
                        搜索
                      </button>
                    </div>
                  </div>
                </a-spin>
              </template>
              <span
                class="search-user-span"
                :style="
                  'font-size:12px;' +
                    (userIds.length ||
                    stageIds.length ||
                    platformIds.length ||
                    productionName ||
                    productSequenceNo ||
                    selTaskStatus ||
                    hasNoteStatus ||
                    finishStatus
                      ? `color:${color}`
                      : '')
                "
                @click.stop="openPop"
                >高级搜索</span
              >
            </a-popover>
            <a-icon
              class="search-user"
              @click.stop="searchQueryForm"
              style="margin-left: 7px; font-size: 14px"
              type="search"
            /> </span
        ></a-input>
        <a-input
          v-if="viewType == 'confirm-list'"
          placeholder="请输入任务名称"
          v-model="confirmTaskName"
          @pressEnter="confirmListSearch"
          style="width: 380px"
        ></a-input>
        <a-popover
          placement="bottom"
          transitionName=""
          v-model="statusPopoverVis"
          :overlayClassName="isAdmin ? 'filter-pop status-pop' : 'filter-pop status-pop status-user-pop'"
          @click="getFilterTypes"
          trigger="click"
        >
          <template slot="content">
            <a-spin :spinning="filterLoading">
              <div class="check-list">
                <a-row style="border-bottom: 1px solid #d9d9d9; font-weight: 700">
                  <a-col :span="isAdmin ? 9 : 12">
                    <a-checkbox
                      :indeterminate="indeterminateStatuts"
                      :checked="checkAllStatus"
                      @change="
                        e => {
                          onCheckAllChange(e, 'status')
                        }
                      "
                      >完成状态</a-checkbox
                    >
                  </a-col>
                  <a-col :span="isAdmin ? 15 : 12">
                    <a-checkbox
                      :indeterminate="indeterminateFunc"
                      :checked="checkAllFunc"
                      @change="
                        e => {
                          onCheckAllChange(e, 'func')
                        }
                      "
                      >日历功能</a-checkbox
                    >
                  </a-col>
                </a-row>
                <a-row style="border-bottom: 1px solid #d9d9d9">
                  <a-col :span="isAdmin ? 9 : 12">
                    <a-checkbox-group
                      v-model="checkedStatusList"
                      :options="statusOptions"
                      @change="
                        list => {
                          checkChange(list, 'status')
                        }
                      "
                    />
                  </a-col>
                  <a-col :span="isAdmin ? 15 : 12">
                    <a-checkbox-group
                      style="width: 100%"
                      v-model="checkedFuncList"
                      @change="
                        list => {
                          checkChange(list, 'func')
                        }
                      "
                    >
                      <a-row>
                        <a-col :span="isAdmin ? 13 : 24">
                          <a-checkbox value="排期">排期</a-checkbox>
                          <a-checkbox value="节假日">节假日</a-checkbox>
                          <a-checkbox value="可安排数量">可安排数量</a-checkbox>
                        </a-col>
                      </a-row>
                    </a-checkbox-group>
                  </a-col>
                </a-row>
                <div
                  class="filter-div"
                  style="display: flex; justify-content: space-between; min-width: 190px; font-size: 14px"
                >
                  <div></div>
                  <div>
                    <button
                      class="add-filter-btn"
                      style="color: rgba(0, 0, 0, 0.65); margin-right: 5px"
                      @click="cancelChunck"
                    >
                      取消
                    </button>
                    <button class="add-filter-btn" :style="'color: #fff;background-color:' + color" @click="saveFilter">
                      确认
                    </button>
                  </div>
                </div>
              </div>
            </a-spin>
          </template>
          <div v-if="viewType != 'list' && viewType != 'confirm-list'" @click="openStatusPop" class="calendar-menu">
            <div>
              <a-icon type="menu-unfold" />
              <span>功能</span>
            </div>
          </div>
        </a-popover>
        <a-select
          v-if="
            viewType == 'list' &&
              (isAdmin || (userInfo.roles && userInfo.roles.some(item => item.roleCode == 'zhuanjia')))
          "
          mode="multiple"
          :showArrow="false"
          :not-found-content="null"
          :filter-option="false"
          placeholder="请输入人员昵称"
          option-filter-prop="label"
          v-model="userIds"
          style="width: 380px"
          @search="userSearch"
          @change="searchQueryForm"
        >
          <a-select-option :label="user.userName" v-for="user in searchUsers" :key="user.userId">
            {{ user.userName }}
          </a-select-option>
        </a-select>
        <a-input
          v-else-if="
            viewType == 'list' &&
              (!isAdmin || (userInfo.roles && userInfo.roles.every(item => item.roleCode != 'zhuanjia')))
          "
          @change="searchQueryForm"
          @pressEnter="searchQueryForm"
          allowClear
          placeholder="请输入作品名称"
          v-model="productionName"
          style="width: 380px"
        />
        <a
          v-if="viewType == 'list'"
          style="margin-left:5px;position:relative;font-size:14px;font-weight:400"
          @click="clearFilter"
        >
          重置筛选条件
        </a>
        <!--         <a-input-group v-else-if="!isSkip&&viewType=='list'" style="width:387px" compact>
          <a-select placeholder="请选择" v-model="inputType">
            <a-select-option value="product">作品名称</a-select-option>
            <a-select-option value="platform">平台名称</a-select-option>
          </a-select>
          <a-input
            style="width: 75%"
            class="search-user-input"
            v-model="productionName"
            placeholder="请输入"
          >
            <template slot="suffix" title="Extra information">
              <a-popover
                placement="bottom"
                v-model="popoverVis"
                overlayClassName="filter-pop filter-pop-task"
                @click="getFilterTypes"
                trigger="click"
              >
                <template slot="content">
                  <a-spin :spinning="filterLoading">
                    <a-collapse v-model="activeKey" :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="我的收藏">
                        <p v-for="(item, index) in filterList" :key="index">
                          <span class="star-span">
                            <a-icon
                              @click="starClick(item)"
                              :style="'color:' + color"
                              type="star"
                              theme="filled"
                            />
                          </span>
                          <span
                            class="filter-push-span"
                            @click="filterListPush(item)"
                          >{{ item.name }}</span>
                        </p>
                      </a-collapse-panel>
                    </a-collapse>
                    <div
                      class="filter-div"
                      style="padding-left:0;padding-bottom:0;border-bottom: 1px solid #d9d9d9;font-size:14px;"
                    >
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">平台名称：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">人员昵称：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-select
                            v-if="!isSkip"
                            mode="multiple"
                            showArrow
                            placeholder="请选择/输入人名"
                            @change="searchQueryForm"
                            option-filter-prop="label"
                            :maxTagCount="1"
                            v-model="userIds"
                            style="width: 200px"
                          >
                            <a-select-option
                              :label="user.userName"
                              v-for="user in allUsers"
                              :key="user.userId"
                            >{{ user.userName }}</a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">阶段：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-select
                            mode="multiple"
                            showArrow
                            placeholder="选择阶段"
                            v-model="stageIds"
                            style="width: 200px"
                          >
                            <a-select-option
                              v-for="stage in stageList"
                              :key="stage.stageId"
                            >{{ stage.stageName }}</a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">作品名称：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-input
                            placeholder="请输入作品名称"
                            v-model="productionName"
                            style="width: 200px"
                          />
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">章节：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-input-number
                            :min="0"
                            placeholder="请输入章节序号"
                            v-model="chapterSequenceNo"
                            style="width: 200px"
                          />
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">截止日期：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-date-picker
                            format="YYYY-MM-DD HH:mm"
                            style="width: 200px"
                            valueFormat="YYYY-MM-DD HH:mm"
                            :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
                            placeholder="请选择截止日期"
                            v-model="deadline"
                          />
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">结算月份：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-month-picker
                            format="YYYY-MM"
                            :show-time="{ format: 'YYYY-MM' }"
                            valueFormat="YYYY-MM"
                            v-model="settlementDate"
                            placeholder="请选择结算月"
                          />
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">考核结果：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-input-group compact style="width:280px">
                            <a-select placeholder="考核类别" style="width:90px">
                              <a-select-option value="1">Between</a-select-option>
                              <a-select-option value="2">Except</a-select-option>
                            </a-select>
                            <a-input
                              style=" width: 72px; text-align: center"
                              placeholder="开始数值"
                            />
                            <a-input
                              style=" width: 29px; border-left: 0; pointer-events: none; backgroundColor: #fff"
                              placeholder="~"
                              disabled
                            />
                            <a-input
                              style="width: 72px; text-align: center; border-left: 0"
                              placeholder="结束数值"
                            />
                          </a-input-group>
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">考核得分：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-input-group compact style="width:280px">
                            <a-input
                              style=" width: 116px; text-align: center"
                              placeholder="开始数值"
                            />
                            <a-input
                              style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"
                              placeholder="~"
                              disabled
                            />
                            <a-input
                              style="width: 116px; text-align: center; border-left: 0"
                              placeholder="结束数值"
                            />
                          </a-input-group>
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">任务状态：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px">
                          <a-select
                            showSearch
                            allowClear
                            showArrow
                            placeholder="请选择任务状态"
                            :maxTagCount="1"
                            v-model="selTaskStatus"
                            option-filter-prop="label"
                            style="width: 200px;position: relative;"
                          >
                            <a-select-option value="FINISHED">已完成</a-select-option>
                            <a-select-option value="STARTED">可开始</a-select-option>
                            <a-select-option value="WAITING">等待中</a-select-option>
                            <a-select-option value="REJECTING">驳回</a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>
                      <a-row style="padding-left:17px">
                        <a-col :span="6">
                          <span class="filter-label">完成状态：</span>
                        </a-col>
                        <a-col :span="18" style="padding-left:15px;line-height: 32px;">
                          <a-radio-group @change="radioChange" v-model="finishStatus">
                            <a-radio value>所有任务</a-radio>
                            <a-radio value="DELAY">拖稿任务</a-radio>
                          </a-radio-group>
                        </a-col>
                      </a-row>
                    </div>
                    <div
                      class="filter-div"
                      style="display: flex; justify-content: space-between;min-width:190px;font-size:14px;"
                    >
                      <a-popconfirm
                        overlayClassName="filter-pop-fileter-name"
                        ok-text="确认"
                        placement="bottomLeft"
                        cancel-text="取消"
                        @confirm="addFilter"
                      >
                        <span slot="icon"></span>
                        <span slot="title">
                          <a-input placeholder="请输入收藏名称" v-model="filterName" style="width: 180px" />
                        </span>
                        <span class="add-filter">+ 收藏当前条件</span>
                      </a-popconfirm>
                      <div>
                        <button
                          class="add-filter-btn"
                          style="color:rgba(0,0,0,0.65);margin-right:5px"
                          @click="removeFilter"
                        >重置</button>
                        <button
                          class="add-filter-btn"
                          :style="'color: #fff;background-color:' + color"
                          @click="saveFilter"
                        >搜索</button>
                      </div>
                    </div>
                  </a-spin>
                </template>
                <span class="search-user-span" @click.stop="openPop">高级搜索</span>
              </a-popover>
              <a-icon
                class="search-user"
                style="margin-left: 3px;position: relative;left: 5px;"
                type="search"
              />
            </template>
          </a-input>
        </a-input-group>-->
      </div>
      <tasks-model ref="tasksModel" @closeTasksModel="closeTasksModel" />
      <my-tasks-table
        v-if="viewType == 'list'"
        :userIds="userIds"
        :sortType="sortType"
        :allUsers="allUsers"
        @setUsers="setUsers"
        @setRowkeys="setRowkeys"
        @setProductName="setProductName"
        :productionName="productionName"
        ref="table"
      />
      <confirm-tasks-table
        v-if="viewType == 'confirm-list'"
        :confirmTaskName="confirmTaskName"
        :userInfo="userInfo"
        @changeConfirmTableTotal="changeConfirmTableTotal"
        ref="confirmTable"
      />

      <manuscript-list ref="manuscript" :title="'上传原稿'" :selectionRows="selectionRows" />
      <sumbmit-modal
        @openTaskModel="openTaskModel"
        ref="sumbmitModal"
        :title="'上传完成稿'"
        :selectionRows="selectionRows"
      />
      <month-list
        ref="monthList"
        @changeTaskList="changeTaskList"
        :allUsers="allUsers"
        :viewType="viewType"
        @getTaskTableData="getTaskTableData"
      />
      <panel-calendar
        @setIsWorkEdit="setIsWorkEdit"
        @setQueryParam="setQueryParam"
        @setSelectKeys="setSelectKeys"
        :getWorkNum="getWorkNum"
        :userInfo="userInfo"
        :filterCheckList="filterCheckList"
        :calendarType="calendarType"
        :pushWorkDays="pushWorkDays"
        :workDays="workDays"
        :workdayAvailableCount="workdayAvailableCount"
        :userIds="userIds"
        :isAdmin="isAdmin"
        :stageList="stageList"
        @getTitle="getTitle"
        @setSkipLoading="setSkipLoading"
        @changeViewTypeToWeek="changeViewTypeToWeek"
        :isView="isView"
        @getDate="getDate"
        @changeFinishDis="changeFinishDis"
        @getKey="getKey"
        :isSkip="isSkip"
        ref="calendarPanel"
        :isMyTask="true"
        :selectKeys="selectKeys"
        :queryParam="queryParam"
        @setSearchDate="setSearchDate"
        v-if="viewType == 'calendar'"
      />
      <a-modal v-model="noWrokVisible" @cancel="handleNoWorkCancel" @ok="handleNoWorkOk" title="请假理由" :width="500">
        <a-textarea :autosize="true" v-model="noWorkReasson" placeholder="请输入请假理由"></a-textarea>
      </a-modal>
      <a-modal
        :keyboard="false"
        :mask-closable="false"
        :closable="false"
        v-model="workVisible"
        title="修改可安排话数"
        :width="scheduleIsDaily ? '35%' : '50%'"
      >
        <a-form-model :model="formData.userFile" ref="workForm" v-bind="workLayout">
          <a-form-model-item v-if="workEditable" prop="workDays" label="通常每周可工作日">
            <a-select mode="multiple" placeholder="通常每周可工作日" v-model="workDays" @change="workDaysChange">
              <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
              <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
              <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
              <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
              <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
              <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
              <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
              <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
            </a-select>
          </a-form-model-item>
          <info-item :label-width="'220'" v-else label="通常每周可工作日">
            <span
              v-for="(item, index) in Array.from(workDays || []).sort((a, b) => {
                return a - b
              })"
              :key="item"
            >
              {{
                item == 1
                  ? '周一'
                  : item == 2
                  ? '周二'
                  : item == 3
                  ? '周三'
                  : item == 4
                  ? '周四'
                  : item == 5
                  ? '周五'
                  : item == 6
                  ? '周六'
                  : item == 7
                  ? '周日'
                  : item == -1
                  ? '跳过中国法定节假日'
                  : ''
              }}{{ index == workDays.length - 1 ? '' : '，' }}
            </span>
          </info-item>
          <div
            v-if="
              (labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length &&
                !isAdmin) ||
                (labelList.filter(
                  item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
                ).length &&
                  isTongchou)
            "
            style="margin-bottom:24px;"
          >
            工作日单一职位最大任务数量：
          </div>
          <div
            v-if="
              (labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length &&
                !isAdmin) ||
                (labelList.filter(
                  item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
                ).length &&
                  isTongchou)
            "
            style="color:red;font-size:12px;position:relative;top:-11px;"
          >
            注意：请在输入框内填写当天只做单一阶段的最大任务数量。
          </div>
          <div
            v-if="
              (labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length &&
                !isAdmin) ||
                (labelList.filter(
                  item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
                ).length &&
                  isTongchou)
            "
            style="color:red;font-size:12px;position:relative;top:-11px;"
          >
            例，一天只做翻译，能做10话则填入10
          </div>
          <div
            v-if="
              (labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length &&
                !isAdmin) ||
                (labelList.filter(
                  item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
                ).length &&
                  isTongchou)
            "
            style="margin-bottom:24px;margin-top:10px;"
          >
            <a-radio-group v-model="scheduleIsDaily">
              <a-radio :disabled="!workEditable" :value="1">每日数值相同</a-radio>
              <a-radio :value="0" :disabled="Array.from(workDays || []).some(item => item == -1) || !workEditable"
                >每日数值不同</a-radio
              >
            </a-radio-group>
          </div>
          <template
            v-if="
              (!isAdmin || isTongchou) &&
                labelList.filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                ).length &&
                scheduleIsDaily
            "
          >
            <template v-if="workEditable">
              <a-form-model-item
                v-for="item in labelList.filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                )"
                :key="item.departId + '-' + item.positionId"
                prop="workCount"
                :label="
                  item.position && item.position.positionName
                    ? (item.depart && item.depart.departName) + '-' + item.position.positionName
                    : ''
                "
              >
                <a-input-number :min="0" placeholder="请输入可工作数量" v-model="item.count" @blur="workBlur" />
              </a-form-model-item>
            </template>
            <template v-else>
              <info-item
                :label-width="'220'"
                v-for="item in labelList.filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                )"
                :key="item.departId + '-' + item.positionId"
                prop="workCount"
                :label="
                  item.position && item.position.positionName
                    ? (item.depart && item.depart.departName) + '-' + item.position.positionName
                    : ''
                "
              >
                {{ item.count }}
              </info-item>
            </template>
          </template>
          <template
            v-else-if="
              (!isAdmin || isTongchou) &&
                labelList.filter(
                  item =>
                    item.week != 'WORK_DAY' &&
                    (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                ).length &&
                !scheduleIsDaily
            "
          >
            <a-table
              :columns="columns.filter((item, index) => workDays.some(i => i == index || !index))"
              :scroll="{ x: '100%', y: '350px' }"
              :pagination="false"
              :data-source="
                weekTableData.filter(
                  data => data.departAndPosition.indexOf('日语部') > -1 || data.departAndPosition.indexOf('韩语部') > -1
                )
              "
              bordered
            >
              <template
                v-for="(col, idx) in ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']"
                :slot="col"
                slot-scope="text, record, index"
              >
                <div :key="idx">
                  <a-input-number
                    :min="0"
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="record[col]"
                    @change="e => handleChange(e, record.departAndPosition, col)"
                  />
                  <template v-else>
                    {{ record[col] }}
                  </template>
                </div>
              </template>
            </a-table>
          </template>
          <a-form-model-item v-if="workEditable" prop="applyReason" label="修改理由">
            <a-input placeholder="请输入修改理由" v-model="formData.applyReason" />
          </a-form-model-item>
        </a-form-model>
        <div></div>
        <template #footer>
          <a-button v-if="!workEditable" @click="calcel"> 关闭 </a-button>
          <a-button v-else @click="calcelWorkEdit"> 取消 </a-button>
          <a-button v-if="!workEditable" key="submit" type="primary" @click="editWork"> 编辑 </a-button>
          <a-button v-else key="submit" type="primary" @click="saveWork"> 保存 </a-button>
        </template>
      </a-modal>
      <a-modal
        :keyboard="false"
        :mask-closable="false"
        :closable="false"
        v-model="workModalVisible"
        title="申请工作"
        :width="420"
      >
        <div v-if="workDates.length > 1">
          请输入（{{ workDates[0] && workDates[0].date }}~{{
            workDates[workDates.length - 1] && workDates[workDates.length - 1].date
          }}）可安排数量。
        </div>
        <div v-if="workDates.length == 1">请输入（{{ workDates[0] && workDates[0].date }}）可安排数量。</div>
        <div style="font-size:12px;color:red;margin-top:5px;margin-bottom:18px;">注意：未填入数值无法安排任务</div>
        <div style="max-height:500px;overflow-y:auto">
          <div
            v-for="item in labelList.filter(
              item =>
                item.week == 'WORK_DAY' &&
                (item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
            )"
            :key="item.departId + '-' + item.positionId"
          >
            <div style="font-weight:bold;margin-bottom:18px">
              {{
                item.position && item.position.positionName
                  ? (item.depart && item.depart.departName) + '-' + item.position.positionName
                  : ''
              }}：
            </div>
            <div
              style="display:flex;margin-bottom:12px;align-items:center"
              v-for="workDate in workDates.filter(workDate => workDate.dp == item.departId + '-' + item.positionId)"
              :key="workDate.date + item.positionId"
            >
              <div style="width:100px;">{{ workDate.date }}：</div>
              <div>
                <a-input-number v-model="workDate.value" :min="0" placeholder="请输入可工作数量/天" />
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <a-button @click="workModalCancel"> 取消 </a-button>
          <a-button key="submit" type="primary" @click="workModalOk"> 提交 </a-button>
        </template>
      </a-modal>
      <term-list-modal ref="termListModal" />
      <!-- <panel-kan-ban :allUsers="allUsers" @getKey="getKey" ref="kanbanPanel" :isMyTask="true" :selectKeys="selectKeys" :queryParam="queryParam" v-if="viewType == 'kanban'" /> -->
    </body-layout>
  </a-spin>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import ActionsTasks from './modules/mytasks/actionsTasks'
import tasksModel from './modules/mytasks/modules/tasksModel'
import termListModal from '@/components/termListModal'
import { SortTwo } from '@icon-park/vue'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
import myTasksTable from '@/components/my-tasks-table'
import confirmTasksTable from '@/components/confirm-tasks-table'
import manuscriptList from '@/components/manuscript-list'
import sumbmitModal from '@/components/sumbmit-modal'
import monthList from '@/components/monthList'
import { createAlink } from '@/utils/util'
import { getAction, postAction, putAction, deleteAction, v1postAction } from '../../api/manage'
import PanelCalendar from '@/views/modules/panel-calendar.vue'
import PanelKanBan from '@/views/modules/panel-kanban.vue'
import { mixinDevice } from '@/utils/mixin.js'
import { handleArrayParams } from '@/utils/util'
import moment from 'moment'
import JSZip from 'jszip'
import { axios } from '@/utils/request'
import FileSaver from 'file-saver'
import { upstatusDownloadFile } from '@/utils/util.js'
export default {
  mixins: [JeecgListMixin, mixinDevice],
  components: {
    SortTwo,
    ActionsTasks,
    sumbmitModal,
    PanelCalendar,
    monthList,
    termListModal,
    PanelKanBan,
    myTasksTable,
    manuscriptList,
    tasksModel,
    confirmTasksTable
  },
  data() {
    let workCountVal = (rule, value, callback) => {
      if (this.labelList.some(item => !item.count)) {
        callback(new Error('请填写所有职位可工作数量'))
      } else {
        callback()
      }
    }
    return {
      checkAllStatus: false,
      confirmTaskName: '',
      labelList: [],
      checkedFuncList: ['排期', '节假日', '可安排数量'],
      checkedStatusList: [],
      formData: {
        userFile: {}
      },
      checkAllFunc: false,
      indeterminateStatuts: false,
      workEditable: false,
      indeterminateFunc: false,
      filterPlatformList: [],
      checkedData: {
        checkedFuncList: ['排期', '节假日', '可安排数量'],
        checkedStatusList: ['已完成', '可开始', '等待', '驳回']
      },
      filterCheckList: [],
      statusOptions: [
        { value: '已完成', label: '已完成' },
        { value: '可开始', label: '可开始' },
        { value: '等待', label: '等待中' },
        { value: '驳回', label: '驳回' }
      ],
      funcOptions: [
        { value: '排期', label: '排期' },
        { value: '节假日', label: '节假日' },
        { value: '可安排数量', label: '可安排数量' }
      ],
      validatorRules: {
        workdayAvailableCount: [{ required: true, message: '请输入工作日每天可安排数量', trigger: 'blur' }],
        workDays: [{ required: true, message: '请选择每周可工作日', trigger: 'blur' }],
        workCount: [{ validator: workCountVal, trigger: 'blur' }]
      },
      vacation: undefined,
      workDayValueShow: false,
      workDayNumValueShow: false,
      color1: '#4a4a4a',
      downProgress: 0,
      sortType: 'asc',
      inputType: 'product',
      chapterSequenceNo: '',
      deadline: null,
      settlementDate: null,
      checkList: [{ checked: false }, { checked: false }],
      finishStatus: [],
      filterLoading: false,
      popoverVis: false,
      statusPopoverVis: false,
      visible: false,
      isView: false,
      isWeek: false,
      isAllDis: false,
      calendarDate: '',
      skipUserId: undefined,
      hasNoteStatus: '2',
      confirmTableTotal: 0,
      noWorkReasson: '',
      noWrokVisible: false,
      workVisible: false,
      workModalVisible: false,
      workDates: [],
      filterList: [],
      selectKeys: [],
      rejectStages: [],
      selectedRowKeys: [],
      activeKey: ['1'],
      weekTableData: [],
      columns: [
        {
          title: '部门-职位（每日可工作数量）',
          dataIndex: 'departAndPosition',
          width: '30%',
          scopedSlots: { customRender: 'departAndPosition' }
        },
        {
          title: '周一',
          dataIndex: 'MONDAY',
          width: '10%',
          scopedSlots: { customRender: 'MONDAY' }
        },
        {
          title: '周二',
          dataIndex: 'TUESDAY',
          width: '10%',
          scopedSlots: { customRender: 'TUESDAY' }
        },
        {
          title: '周三',
          dataIndex: 'WEDNESDAY',
          width: '10%',
          scopedSlots: { customRender: 'WEDNESDAY' }
        },
        {
          title: '周四',
          dataIndex: 'THURSDAY',
          width: '10%',
          scopedSlots: { customRender: 'THURSDAY' }
        },
        {
          title: '周五',
          dataIndex: 'FRIDAY',
          width: '10%',
          scopedSlots: { customRender: 'FRIDAY' }
        },
        {
          title: '周六',
          dataIndex: 'SATURDAY',
          width: '10%',
          scopedSlots: { customRender: 'SATURDAY' }
        },
        {
          title: '周日',
          dataIndex: 'SUNDAY',
          width: '10%',
          scopedSlots: { customRender: 'SUNDAY' }
        }
      ],

      mytasksDetialPath: '/translate/mytasks/detail',
      mytasksUploadPath: '/translate/mytasks/upload',
      translateMyLocalDownPage: '/translate/mytasks/downLocal',
      translateUploadserver: '/translate/mytasks/uploadServer',
      translateMyDowntasksDetialPath: '/translate/batchdown/index',
      translateMytasksDetialPath: '/translate/mytasks/index',
      translateEditorPath: '/translate/editor',
      btnLoadding: false,
      url: {
        list: '/tasks/list'
      },
      name: '',
      workLayout: {
        labelCol: {
          style: 'width: 220px',
          span: 10
        },
        wrapperCol: {
          span: 14
        }
      },
      allUsers: [],
      calendarType: 'dayGridMonth',
      userIds: [],
      stageIds: [],
      platformIds: [],
      stageList: [],
      taskStatus: '0',
      productionName: '',
      productSequenceNo: '',
      startDate: '',
      endDate: '',
      workDays: [],
      isAdmin: false,
      viewType: 'calendar',
      isSelect: false,
      selectDis: false,
      workdayAvailableCount: 0,
      dateInfo: null,
      settlementMonth: '',
      nowDate: '',
      isWorkEdit: false,
      isSkip: false,
      skipLoading: false,
      isCalendarCancel: false,
      isCalendarDropCancel: false,
      isWorkDay: false,
      isHoliDay: false,
      isSleep: false,
      userInfo: {},
      finishDis: false,
      color: '',
      selTaskStatus: [],
      filterStageList: [],
      pushWorkDays: [],
      filterName: '',
      isEditSelect: false,
      searchUsers: [],
      checkedList: [],
      rejectShow: false,
      deleteSearchIds: [],
      deleViewTypes: [],
      isTongchou: false,
      scheduleIsDaily: 1,
      weekMap: {
        MONDAY: '周一',
        TUESDAY: '周二',
        WEDNESDAY: '周三',
        THURSDAY: '周四',
        FRIDAY: '周五',
        SATURDAY: '周六',
        SUNDAY: '周日'
      },
      funcShow: false
    }
  },
  watch: {
    startDate(startDate) {
      if (typeof startDate == 'string') return
      this.queryParam.startDate = startDate?.format('YYYY-MM-DD') || ''
    },
    endDate(endDate) {
      if (typeof endDate == 'string') return
      this.queryParam.endDate = endDate?.format('YYYY-MM-DD') || ''
    },
    settlementMonth(settlementMonth) {
      this.queryParam.settlementMonth = settlementMonth?.format('YYYY-MM') || ''
      this.queryParam.settlementMonth = this.queryParam.settlementMonth + '-01'
    },
    userIds() {
      if (this.viewType == 'list') return
      if (!this.userIds.length) {
        this.stageIds = []
        this.platformIds = []
        this.filterStageList = []
        this.filterPlatformList = []
      }
      this.getFilterStageList()
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectionRows = selectedRows
          if (this.selectionRows.length == 1) {
            this.dataSource.forEach(item => {
              if (item.taskStatus != this.selectionRows[0].taskStatus) {
                item.disabled = true
              }
            })
            this.dataSource = [...this.dataSource]
          } else {
            this.dataSource.forEach(item => {
              if (item.taskStatus === '等待' && item.taskName != '给稿') {
                item.disabled = false
              }
            })
            this.dataSource = [...this.dataSource]
          }
        }
        /*         getCheckboxProps: record => ({
          props: {
            disabled:
              (record.taskStatus === '等待' && record.taskName != '给稿') || record.taskStatus == '已完成'
                ? true
                : this.selectionRows.length > 0
                ? record.taskStatus != this.selectionRows[0].taskStatus
                  ? true
                  : false
                : false
          }
        }) */
      }
    },
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    }
  },
  methods: {
    moment,
    confirmListSearch() {
      if (this.$refs.confirmTable) {
        this.$refs.confirmTable.search()
      }
    },
    openTaskModel(data) {
      this.$refs.tasksModel.open(data.noUpdateTasks, data.hasFeedback)
    },
    sortTable() {
      if (this.$refs.table) {
        this.$refs.table.sortChangeEvent(this.sortType)
        if (this.sortType == 'asc') {
          this.sortType = 'desc'
        } else {
          this.sortType = 'asc'
        }
      }
    },
    cancelChunck() {
      this.statusPopoverVis = false
      this.checkedFuncList = JSON.parse(this.checkedData.content).checkedFuncList
      this.checkedStatusList = JSON.parse(this.checkedData.content).checkedStatusList
    },
    clearFilter() {
      if (this.$refs.table) {
        if (this.$refs.table) this.$refs.table.clearFilterData()
      }
    },
    async handleTaskStart(type) {
      this.visible = false
      if (type == 'list') {
        const res = await getAction('/tasks/batch/resetTasks/' + this.selectionRows.map(item => item.id).join(','))
        if (res.code == 200) {
          this.$message.success('可开始')
          if (this.$refs.table) this.$refs.table.getTableData()
          this.selectKeys = []
          this.selectionRows = []
        } else {
          this.$message.error(res.msg)
        }
      } else {
        const res = await getAction('/tasks/batch/resetTasks/' + this.selectKeys.map(item => item.id).join(','))
        if (res.code == 200) {
          this.$message.success('可开始')
          if (this.$refs.calendarPanel) {
            this.$refs.calendarPanel.handleSelectOn()
            this.$refs.calendarPanel.reloadData()
          }
          this.selectKeys = []
          this.selectionRows = []
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    downImg(fileList) {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      fileList.forEach((item, i) => {
        const promise = this.getFile(item).then(data => {
          // 下载文件, 并存成blob对象
          const file_name = item.name // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
          zip.file(file_name, data, { binary: true }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
          FileSaver.saveAs(content, '翻译JPG.zip') // 利用file-saver保存文件  自定义文件名
        })
      })
    },
    async downloadOver(isSection) {
      let arr = []
      this.selectionRows.forEach(item => {
        if (arr.every(a => a.chapterId != item.chapterId)) {
          arr.push(item)
        }
      })
      let that = this
      arr.forEach(async (item, index) => {
        let productOrder = item.productOrder + ''
        let chapterOrder = item.chapterOrder + ''
        if (productOrder.length == 1) {
          productOrder = '00' + productOrder
        } else if (productOrder.length == 2) {
          productOrder = '0' + productOrder
        }
        if (chapterOrder.length == 1) {
          chapterOrder = '00' + chapterOrder
        } else if (chapterOrder.length == 2) {
          chapterOrder = '0' + chapterOrder
        }
        const token = Vue.ls.get(ACCESS_TOKEN)
        const res = await axios.post(
          Vue.prototype.API_BASE_URL +
            '/finish/manuscript/download?chapterId=' +
            item.chapterId +
            '&isSection=' +
            isSection,
          {},
          {
            headers: { 'X-Access-Token': token },
            responseType: 'blob',
            onDownloadProgress(progress) {
              // 监听下载进度的方法
              let downProgress = Math.round((100 * progress.loaded) / progress.total)
              if (progress.total) {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productionName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: downProgress == 100 ? 0.3 : null
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productionName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: downProgress == 100 ? 0.3 : null
                  })
                }
              } else {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    duration: null,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M'
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M',
                    duration: null
                  })
                }
              }
              open = true
              console.log(that.downProgress, progress.loaded, progress.total, 123)
            }
          }
        )
        if (!res) return
        const blob = res
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        let fileName = item.productionName + '-' + chapterOrder + '-终稿'
        if (item.departName.indexOf('原创部') == -1) {
          fileName = productOrder + '-' + chapterOrder
        }
        a.href = url
        a.download = fileName + '.zip'
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    changeConfirmTableTotal(value) {
      this.confirmTableTotal = value
    },
    getFile(item) {
      let url = item.url
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    downloadJpg() {
      if (this.selectionRows.every(item => item.hasTranslateText)) {
        /*         let files = []
        this.selectionRows.forEach(item => {
          item.originalManuscripts?.forEach(i => {
            files.push({ url: i.filePath, name: i.fileName.replace('psd', 'jpg') })
          })
        })
        this.downImg(files) */
        this.selectionRows.forEach(taskDetail => {
          localStorage.setItem('translate_record', JSON.stringify(taskDetail))
          let pageNum = (taskDetail.originalManuscripts && taskDetail.originalManuscripts.length) || 0
          window.open(
            `/translate/editor/${taskDetail.taskId}/${taskDetail.productionId}/${taskDetail.chapterId}/${taskDetail.platformId}?productionName=` +
              taskDetail.productionName +
              `&isDownload=true&pageNum=${pageNum}`
          )
        })
      } else {
        this.$message.error('无法下载翻译JPG，请确认是否有翻译文本')
      }
    },
    async downloadMake() {
      this.selectionRows.forEach(async item => {
        const rest = await v1postAction('/psd/get_chapter_make_psd_link', {
          productionId: item.productionId,
          chapterId: item.chapterId
        })
        if (rest.code == 200) {
          let postData = { list: [] }
          rest.data.forEach(data => {
            let makeArr = []
            data.textAreas.forEach(textArea => {
              if (!textArea.characterSentences.length) return
              let textArr = textArea.showText.split('\n')
              var reg = /^[a-zA-Z]+$/

              textArr = textArr.sort((a, b) => a.replaceAll('.', '').length - b.replaceAll('.', '').length)
              let textArrLength = 0
              textArr[textArr.length - 1].split('').forEach(text => {
                if (reg.test(text) || text == ' ') {
                  textArrLength = textArrLength + 0.5
                } else if (text == '.') {
                  textArrLength = textArrLength + 0.3
                } else {
                  textArrLength++
                }
              })
              textArrLength = textArrLength.toFixed(0) - 0
              let maxWidth =
                (
                  textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                ).toFixed(0) - 0
              if (textArea.textLayout == 2) {
                maxWidth =
                  (
                    textArr.length *
                    textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                    textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                  ).toFixed(0) - 0
              }
              let maxHeight =
                (
                  textArr.length *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                ).toFixed(0) - 0
              if (textArea.textLayout == 2) {
                maxHeight =
                  (
                    textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                  ).toFixed(0) - 0
              }
              let changeWidth = ((maxWidth - textArea.textAreaTranslationRegion.width) / 2).toFixed(0) - 0
              let changeHeight = ((maxHeight - textArea.textAreaTranslationRegion.height) / 2).toFixed(0) - 0
              let pushArr = []
              let brIndex = [-1]
              let newBrIndex = [-1]
              let changeBrIndex = []
              let phoneticArr = []
              let pushPhoneticText = ''
              let theX = 0
              let theY = 0
              let phoneticBrIndexOftext = []
              let arr = []
              textArea.showTexts = []
              textArea.characterSentences.forEach((text, textIndex) => {
                if (text.characterText == '\n') text.characterText = '<br>'
                if (text.characterText == '<br>') newBrIndex.push(textIndex)
                if (text.phonetic && text.phonetic.phoneticText) {
                  phoneticBrIndexOftext.push(newBrIndex[newBrIndex.length - 1])
                }
                if (text.characterText != '<br>') {
                  arr.push(text)
                } else {
                  textArea.showTexts.push({ pId: '', texts: arr, textIndex })
                  arr = []
                  if (
                    textIndex == textArea.characterSentences.length - 1 &&
                    !arr.length &&
                    text.characterText == '<br>'
                  ) {
                    textArea.showTexts.push({
                      pId: '',
                      texts: [
                        {
                          ...textArea.characterSentences[0],
                          characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                          characterText: ' '
                        }
                      ],
                      pushTtextIndex: textIndex
                    })
                  }
                }
                if (textIndex == textArea.characterSentences.length - 1 && arr.length) {
                  textArea.showTexts.push({ pId: '', texts: arr, pushTtextIndex: textIndex })
                }
              })
              textArea.showTexts = textArea.showTexts.sort((a, b) => b.texts.length - a.texts.length)
              phoneticBrIndexOftext = Array.from(new Set(phoneticBrIndexOftext))
              let enNum = 0
              textArea.characterSentences.forEach((text, textIndex) => {
                let markArr = []
                if (text.characterText == '<br>') {
                  brIndex.push(textIndex)
                  enNum = 0
                }
                let minusWidth = 0
                /*               if (changeWidth < 0) {
                if (phoneticBrIndexOftext.length) {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                } else {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                }
              } */
                minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                let textAlignWidth = 0
                if (text.font.textAlign == 'right' && textArea.showTexts[0]) {
                  textAlignWidth = textArea.showTexts[0].texts.length * text.font.fontSize
                }
                theX =
                  textArea.textLayout == 1
                    ? textArea.textAreaTranslationRegion.x - changeWidth + textAlignWidth
                    : textArea.textAreaTranslationRegion.x -
                      changeWidth +
                      phoneticBrIndexOftext.length * ((text.font.fontSize - 1) / 2 + 1) +
                      minusWidth
                theY =
                  textArea.textLayout == 1
                    ? textArea.textAreaTranslationRegion.y - changeHeight + text.font.fontSize
                    : textArea.textAreaTranslationRegion.y - changeHeight + textAlignWidth
                let pushCharacterText = text.characterText == '<br>' ? '\r' : text.characterText
                if (
                  textArea.characterSentences[textIndex - 1] &&
                  textArea.characterSentences[textIndex - 1].characterText == '<br>' &&
                  textArea.characterSentences[textIndex].characterText == ' '
                ) {
                  pushCharacterText = ''
                }
                let textPushArr = [
                  pushCharacterText == '\n' ? '\r' : pushCharacterText,
                  (text.font.fontSize - 1) / 4 - 0,
                  text.font.postScriptName,
                  { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                  { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                  text.font.textAlign,
                  0.0,
                  (text.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  theX,
                  theY,
                  text.font.strokeWidth || 0,
                  text.font.strokeColor
                    ? {
                        red: text.font.strokeColor.red,
                        green: text.font.strokeColor.green,
                        blue: text.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  0,
                  text.characterSentenceId
                ]
                if (reg.test(text.characterText)) {
                  if (
                    text.characterText == 'f' ||
                    text.characterText == 'i' ||
                    text.characterText == 'j' ||
                    text.characterText == 'l' ||
                    text.characterText == 't'
                  ) {
                    enNum = enNum + 0.5
                  } else {
                    if (text.characterText == 'A') {
                      enNum = enNum + 0.25
                    } /* else if(text.characterText=='B'){
                    enNum = enNum + 0.3
                  } */ else {
                      enNum = enNum + 0.3
                    }
                  }
                }
                if (text.font.hasMark) {
                  let markText = '.'
                  let markX = 0
                  if (!brIndex.length) {
                    markX = (textIndex - 1 - enNum) * text.font.fontSize + (text.font.fontSize / 2 - 0) - 3
                    if (textIndex > 8) {
                      markX = markX + ((textIndex - 8) / 3) * 2
                    }
                    if (text.font.isBold) {
                      markX = markX + textIndex
                    }
                  } else {
                    markX =
                      (textIndex - brIndex[brIndex.length - 1] - 1 - enNum) * text.font.fontSize +
                      (text.font.fontSize / 2 - 0) -
                      3
                    if (textIndex - brIndex[brIndex.length - 1] > 8) {
                      markX = markX + ((textIndex - brIndex[brIndex.length - 1] - 8) / 3) * 2
                    }
                    if (text.font.isBold) {
                      markX = markX + (textIndex - brIndex[brIndex.length - 1])
                    }
                  }
                  if (text.font.textAlign == 'right') {
                    markX = theX - markX
                  }
                  let markPushArr = [
                    markText,
                    ((text.font.fontSize - 1) / 4).toFixed(0) - 0,
                    text.font.postScriptName,
                    { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                    { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                    text.font.textAlign,
                    0.0,
                    (text.font.lineSpacing * 100).toFixed(0) - 0,
                    0.0,
                    textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                    textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                    text.font.textAlign == 'right' ? markX : theX + markX,
                    theY +
                      (brIndex.length - 2) +
                      (text.font.fontSize - 1) / 4 +
                      (((brIndex.length - 1) * text.font.fontSize * text.font.lineSpacing).toFixed(0) - 0),
                    text.font.strokeWidth || 0,
                    text.font.strokeColor
                      ? {
                          red: text.font.strokeColor.red,
                          green: text.font.strokeColor.green,
                          blue: text.font.strokeColor.blue
                        }
                      : { red: 255, green: 255, blue: 255 },
                    'antiAliasStrong',
                    textArea.textLayout == 1 ? 0 : 1,
                    0,
                    text.characterSentenceId
                  ]
                  markArr.push(markPushArr)
                  makeArr.push(markArr)
                }
                pushArr.push(textPushArr)
                if (text.phonetic && text.phonetic.phoneticText) {
                  if (text.phonetic.font) {
                    text.phonetic.font.postScriptName = text.font.postScriptName
                  }
                  let isFirstText = false
                  if (!textIndex || textArea.characterSentences[textIndex - 1].characterText == '<br>')
                    isFirstText = true
                  phoneticArr.push({
                    targetCharacterIds: text.phonetic.targetCharacterIds,
                    brNum: brIndex.length - 1,
                    phonetic: text.phonetic,
                    text,
                    textIndex,
                    isFirstText
                  })
                }
                if (
                  phoneticArr.findIndex(p => p.textIndex == textIndex) > -1 &&
                  phoneticArr.find(p => p.textIndex == textIndex).isFirstText &&
                  phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText.length > 3
                ) {
                  pushPhoneticText = ''
                  let pushPhoneticTextLength = phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText
                    .length
                  for (let i = 0; i < pushPhoneticTextLength - 3; i++) {
                    pushPhoneticText = pushPhoneticText + ' '
                  }
                }
              })
              if (brIndex.length && phoneticArr.length) {
                brIndex.forEach((brIdx, brIdxIndex) => {
                  brIdx = brIdx + 1 + brIdxIndex
                  pushArr.splice(brIdx, 0, [
                    pushPhoneticText,
                    (phoneticArr[0].phonetic.font.fontSize - 1) / 4 - 0,
                    pushArr[0][2],
                    pushArr[0][3],
                    pushArr[0][4],
                    pushArr[0][5],
                    pushArr[0][6],
                    pushArr[0][7],
                    pushArr[0][8],
                    pushArr[0][9],
                    pushArr[0][10],
                    theX,
                    theY,
                    pushArr[0][13],
                    pushArr[0][14],
                    pushArr[0][15],
                    textArea.textLayout == 1 ? 0 : 1,
                    pushArr[0][17]
                  ])
                  changeBrIndex.push(brIdx - 1)
                })
              }
              if (phoneticArr.length) {
                let phoneticBrIndex = [...changeBrIndex]
                let pushPhoneticBrIndexArr = []
                let pushPhoneticBrNum = 0
                phoneticArr.forEach((p, pIdx) => {
                  if (pushPhoneticBrIndexArr.indexOf(changeBrIndex[p.brNum]) > -1) {
                    return
                  }
                  pushArr.splice(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum, 0, [
                    '\r',
                    pushArr[0][1],
                    pushArr[0][2],
                    pushArr[0][3],
                    pushArr[0][4],
                    pushArr[0][5],
                    pushArr[0][6],
                    pushArr[0][7],
                    pushArr[0][8],
                    pushArr[0][9],
                    pushArr[0][10],
                    theX,
                    theY,
                    pushArr[0][13],
                    pushArr[0][14],
                    pushArr[0][15],
                    textArea.textLayout == 1 ? 0 : 1,
                    pushArr[0][17]
                  ])
                  /*                 changeBrIndex.forEach(c => {
                  if (
                    pushArr[c + pushPhoneticBrNum] &&
                    pushArr[c + pushPhoneticBrNum - 1] &&
                    pushArr[c + pushPhoneticBrNum][0] == '\r' &&
                    pushArr[c + pushPhoneticBrNum - 1][0] == '\r'
                  ) {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum - 1)
                  } else {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum)
                  }
                }) */
                  let pushBrNum = changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum
                  phoneticBrIndex[p.brNum] = pushBrNum
                  /* phoneticBrIndex.push(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum) */
                  pushPhoneticBrIndexArr.push(changeBrIndex[p.brNum])
                  pushPhoneticBrNum++
                })
                phoneticArr = phoneticArr.reverse()
                let phoneticSzie = 0
                let moveNum = 0
                brIndex = []
                pushArr.forEach((text, textIndex) => {
                  if (
                    text[0] == '\r' &&
                    ((pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r') ||
                      (pushArr[textIndex + 1] && pushArr[textIndex + 1][0] != '\r') ||
                      !textIndex)
                  ) {
                    /* if(pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r'&&pushArr[textIndex] && pushArr[textIndex][0] == '\r'){
                    brIndex.push(textIndex)
                  } */
                    brIndex.push(textIndex)
                  }
                })
                phoneticArr.forEach((p, pIdx) => {
                  if (!p.targetCharacterIds) p.targetCharacterIds = []
                  let menuis = brIndex[0] == 0 ? 0 : 1
                  moveNum =
                    pushArr.findIndex(text => text[18] == p.targetCharacterIds[0]) - brIndex[p.brNum - menuis] - 1
                  /* textArea.showText
                  .split('\n')
                  [p.brNum].split('')
                  .forEach((t, tEnIdx) => {
                    if (
                      reg.test(t) &&
                      textArea.characterSentences.findIndex(te => te.characterSentenceId == p.targetCharacterIds[0]) >
                        tEnIdx
                    ) {
                      moveNum--
                    }
                  }) */
                  p.moveNum = moveNum
                })
                let addTotal = phoneticArr.length
                let addArr = []
                phoneticArr.forEach((p, pIdx) => {
                  phoneticSzie = (p.phonetic.font.fontSize - 1) / 4 - 0
                  let sortNum = phoneticArr
                    .filter(cp => cp.brNum == p.brNum)
                    .sort((a, b) => {
                      return a.moveNum - b.moveNum
                    })
                  let addNum = 1
                  if (p.phonetic.phoneticText.length > 1) {
                    addNum = 0
                  } else if (p.phonetic.phoneticText.length == 1) {
                    addNum = 2
                  }
                  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    addNum = -2
                  }
                  console.log(p.moveWidth, p, sortNum, addNum)
                  if (p.moveNum != sortNum[0].moveNum) {
                    if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) {
                      if (p.phonetic.phoneticText.length > 2) {
                        addNum = 0
                      } else if (p.phonetic.phoneticText.length == 2) {
                        addNum = 2
                      } else if (p.phonetic.phoneticText.length == 1) {
                        addNum = 3
                      }
                      if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                        addNum = addNum + 1
                      if (!p.phonetic.font.postScriptName) {
                        this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                      }
                    }
                    p.moveWidth =
                      (p.moveNum - sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].moveNum - 1) * 8 + addNum
                    let changeNum =
                      (sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].targetCharacterIds.length - 1) * 4
                    /*                     sortNum.forEach((s,sIdx)=>{
                      if(sIdx<sortNum.findIndex(s => s.moveNum == p.moveNum)){
                        changeNum=changeNum+(s.targetCharacterIds.length-1)*3
                      }
                    }) */
                    p.moveWidth = p.moveWidth - changeNum
                    if (p.phonetic.phoneticText.length > 5 && p.targetCharacterIds.length == 1) {
                      p.moveWidth = p.moveWidth - 1
                    }
                    if (p.targetCharacterIds.length > 1) {
                      p.moveWidth = p.moveWidth + (p.targetCharacterIds.length - 1) * 4
                    }
                  } else {
                    if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                      addNum = addNum + (p.moveNum == 1 ? 2 : 1)
                    if (!p.phonetic.font.postScriptName) {
                      this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                    }
                    if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) addNum = 1
                    p.moveWidth = (p.moveNum - 2 + p.targetCharacterIds.length) * 10 + addNum - 2
                    /* if (p.phonetic.phoneticText.length == 4 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 2
                  } */
                    /*  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  }
                  if (p.phonetic.phoneticText.length == 2 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  } */
                    if (p.moveNum == 1) {
                      p.moveWidth = (p.targetCharacterIds.length - 1) * 4 + 2
                    }
                  }
                  let addText = ''
                  for (let i = 0; i < p.moveWidth; i++) {
                    addText = addText + ' '
                  }
                  addArr.push({
                    index: phoneticBrIndex[p.brNum] + (phoneticBrIndex[p.brNum] ? addTotal : addTotal - 1),
                    addText,
                    text: p.text,
                    p
                  })
                  addTotal--
                  pushArr.splice(phoneticBrIndex[p.brNum] ? phoneticBrIndex[p.brNum] + 1 : 0, 0, [
                    p.phonetic.phoneticText,
                    (p.phonetic.font.fontSize - 1) / 4 - 0,
                    p.phonetic.font.postScriptName,
                    {
                      red: p.text.font.fontColor.red,
                      green: p.text.font.fontColor.green,
                      blue: p.text.font.fontColor.blue
                    },
                    { bold: p.phonetic.font.isBold ? true : false, italic: p.phonetic.font.isIncline ? true : false },
                    'center',
                    p.phonetic.font.letterSpacing,
                    (p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                    0.0,
                    p.phonetic.font.textHeight * 100,
                    p.phonetic.font.textWidth * 100,
                    theX,
                    theY,
                    p.phonetic.font.strokeWidth || 0,
                    p.phonetic.font.strokeColor
                      ? {
                          red: p.phonetic.font.strokeColor.red,
                          green: p.phonetic.font.strokeColor.green,
                          blue: p.phonetic.font.strokeColor.blue
                        }
                      : { red: 255, green: 255, blue: 255 },
                    'antiAliasStrong',
                    textArea.textLayout == 1 ? 0 : 1,
                    1
                  ])
                })
                addArr = addArr.reverse()
                addArr.forEach((a, aIdx) => {
                  pushArr.splice(a.index + aIdx, 0, [
                    a.addText,
                    (a.p.phonetic.font.fontSize - 1) / 4 - 0,
                    'AdobeHeitiStd-Regular',
                    {
                      red: a.text.font.fontColor.red,
                      green: a.text.font.fontColor.green,
                      blue: a.text.font.fontColor.blue
                    },
                    {
                      bold: a.p.phonetic.font.isBold ? true : false,
                      italic: a.p.phonetic.font.isIncline ? true : false
                    },
                    'center',
                    a.p.phonetic.font.letterSpacing,
                    (a.p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                    0.0,
                    100,
                    100,
                    theX,
                    theY,
                    a.p.phonetic.font.strokeWidth || 0,
                    a.p.phonetic.font.strokeColor
                      ? {
                          red: a.p.phonetic.font.strokeColor.red,
                          green: a.p.phonetic.font.strokeColor.green,
                          blue: a.p.phonetic.font.strokeColor.blue
                        }
                      : { red: 255, green: 255, blue: 255 },
                    'antiAliasStrong',
                    textArea.textLayout == 1 ? 0 : 1,
                    1
                  ])
                })
                if (pushPhoneticText.length) {
                  pushArr.forEach(a => {
                    if (a[16] == 1) {
                      a[11] = a[11] + pushPhoneticText.length * (phoneticSzie / 2)
                    } else {
                      a[12] = a[12] - pushPhoneticText.length * (phoneticSzie / 2)
                    }
                  })
                }
              }
              makeArr.push(pushArr)
            })

            postData.list.push(makeArr)
          })

          const res = await v1postAction('/psd/test', {
            ...postData,
            productionId: item.productionId,
            chapterId: item.chapterId
          })
          if (res.code == 1) {
            let name = item.productName + '-' + item.chapterOrder + '-制作稿.zip'
            let xhr = new XMLHttpRequest()
            // GET请求,link,async(是否异步)
            xhr.open('GET', '/api/v1' + res.data, true)
            xhr.setRequestHeader('X-Access-Token', Vue.ls.get(ACCESS_TOKEN))
            xhr.responseType = 'blob'
            xhr.onload = function(e) {
              if (this.status == 200) {
                let blob = this.response
                let a = document.createElement('a')
                // blob.type = "application/octet-stream";
                //创键临时link对象
                let url = window.URL.createObjectURL(new Blob([blob]))
                a.href = url
                a.download = name
                a.click()
                // 释放之前创建的URL对象
                window.URL.revokeObjectURL(url)
                a.remove()
              }
            }
            // //发送请求
            xhr.send()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleChange(value, departAndPosition, column) {
      const newData = [...this.weekTableData]
      const target = newData.find(item => departAndPosition === item.departAndPosition)
      if (target) {
        target[column] = value
        this.weekTableData = newData
      }
    },
    calcelWorkEdit() {
      /*       this.workEditable = false
      this.weekTableData.forEach(item => {
        item.editable = false
      })
      this.workDays = this.userInfo.userFile.workDays || [] */
      this.workEditable = false
      this.workDays =
        this.userInfo.userFile.workDays.sort((a, b) => {
          return a - b
        }) || []
      this.weekTableData.forEach(item => {
        item.editable = false
      })
      this.getWorkNum()
    },
    editWork() {
      this.workEditable = true
      this.weekTableData.forEach(item => {
        item.editable = true
      })
    },
    workBlur() {
      this.$refs['workForm'].validate(v => {})
    },
    calcel() {
      this.workVisible = false
      this.workEditable = false
      this.weekTableData.forEach(item => {
        item.editable = false
      })
      this.getWorkNum()
    },
    async saveWork() {
      let volid = true
      let volidMsg = ''
      if (!this.scheduleIsDaily) {
        if (this.workDays.indexOf(1) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'MONDAY')
        }
        if (this.workDays.indexOf(2) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'TUESDAY')
        }
        if (this.workDays.indexOf(3) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'WEDNESDAY')
        }
        if (this.workDays.indexOf(4) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'THURSDAY')
        }
        if (this.workDays.indexOf(5) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'FRIDAY')
        }
        if (this.workDays.indexOf(6) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'SATURDAY')
        }
        if (this.workDays.indexOf(7) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'SUNDAY')
        }
      }
      this.weekTableData.forEach(i => {
        this.labelList
          .filter(
            item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
          )
          .forEach(item => {
            if (i.departAndPosition == item.depart.departName + '-' + item.position.positionName) {
              if (i[item.week]) {
                item.count = i[item.week]
              } else if (!i[item.week] && item.week != 'WORK_DAY') {
                if (!this.scheduleIsDaily) {
                  volid = false
                  volidMsg =
                    item.depart.departName +
                    '-' +
                    item.position.positionName +
                    '（' +
                    this.weekMap[item.week] +
                    '工作数量未填写）'
                }
              }
            }
          })
      })
      if (this.scheduleIsDaily) {
        this.labelList
          .filter(
            item =>
              item.week == 'WORK_DAY' &&
              (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
          )
          .forEach(item => {
            if (!item.count) {
              volid = false
              volidMsg = item.depart.departName + '-' + item.position.positionName + '（工作数量未填写）'
            }
          })
      }
      if (this.isAdmin) volid = true
      if (!volid) {
        this.$message.error(volidMsg)
        return
      }

      let data = {
        dataList: this.labelList,
        userId: this.userInfo.id,
        scheduleIsDaily: this.scheduleIsDaily == 1,
        applyReason: this.formData.applyReason,
        userFile: {
          workDays: this.workDays
        }
      }
      const res = await postAction('/taskSchedule/v2', data)
      if (res.code == 200) {
        this.$message.success('保存成功')
        window.location.reload()
        this.workVisible = false
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async getWorkNum() {
      const res = await getAction('/taskSchedule?userId=' + this.userInfo.id)
      if (res.code == 200) {
        this.labelList = res.data
        this.weekTableData = []
        this.workEditable = false
        this.scheduleIsDaily = this.userInfo.userFile.scheduleIsDaily ? 1 : 0
        this.labelList
          ?.filter(item => item.week != 'WORK_DAY')
          ?.forEach(item => {
            if (
              this.weekTableData.every(
                i => i.departAndPosition != item.depart.departName + '-' + item.position.positionName
              )
            ) {
              this.weekTableData.push({
                departAndPosition: item.depart.departName + '-' + item.position.positionName,
                editable: false,
                key: item.depart.departId + '-' + item.position.positionId
              })
            }
          })
        this.labelList
          ?.filter(item => item.week != 'WORK_DAY')
          ?.forEach(item => {
            this.weekTableData.forEach(i => {
              if (i.departAndPosition == item.depart.departName + '-' + item.position.positionName) {
                if (item.week == 'MONDAY') {
                  i.MONDAY = item.count
                } else if (item.week == 'TUESDAY') {
                  i.TUESDAY = item.count
                } else if (item.week == 'WEDNESDAY') {
                  i.WEDNESDAY = item.count
                } else if (item.week == 'THURSDAY') {
                  i.THURSDAY = item.count
                } else if (item.week == 'FRIDAY') {
                  i.FRIDAY = item.count
                } else if (item.week == 'SATURDAY') {
                  i.SATURDAY = item.count
                } else if (item.week == 'SUNDAY') {
                  i.SUNDAY = item.count
                }
              }
            })
          })
        if (this.userInfo.userFile.scheduleIsDaily) {
          res.data.forEach(item => {
            if (
              item.week == 'WORK_DAY' &&
              this.labelList.findIndex(
                i =>
                  i.position.positionName == item.position.positionName && i.depart.departName == item.depart.departName
              ) == -1
            ) {
              this.labelList.push(item)
            }
          })
        }
      }
    },
    changeTaskList(data) {
      if (data.settlementDate && !data.settleStatus) {
        let arr = []
        arr = data.setArr || []
        this.$refs.table.tableData.forEach(item => {
          item.children?.forEach(i => {
            i.children?.forEach(d => {
              arr.forEach(a => {
                if (d.taskId == a.taskId) {
                  d.settlementDate = a.settlementDate.split('-')[0] + '-' + a.settlementDate.split('-')[1]
                }
              })
            })
          })
        })
        this.$refs.table.clearSelectKeys()
      } else if (!data.settlementDate && data.settleStatus) {
        this.selectionRows.forEach(item => {
          item.settleStatus = data.settleStatus
        })
        this.$refs.table.clearSelectKeys()
      } else if (data.settlementDate && data.settleStatus) {
        let arr = []
        this.selectionRows.forEach(item => {
          item.settleStatus = data.settleStatus
        })
        arr = data.setArr || []
        this.$refs.table.tableData.forEach(item => {
          item.children?.forEach(i => {
            i.children?.forEach(d => {
              arr.forEach(a => {
                if (d.taskId == a.taskId) {
                  d.settlementDate = a.settlementDate.split('-')[0] + '-' + a.settlementDate.split('-')[1]
                }
              })
            })
          })
        })
        this.$refs.table.clearSelectKeys()
      }
    },
    openMonthList() {
      this.$refs.monthList.open(this.selectionRows)
    },
    priorityChange(item, type) {
      if (this.rejectStages.length == 1) {
        this.rejectStages[0].stageName = this.rejectStages[0].stageName.split('-')[0] + '-' + type
        this.checkedList = this.rejectStages
        this.submitTask()
      }
      item.stageName = item.stageName.split('-')[0] + '-' + type
      item.checked = true
      if (
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      }
    },
    submitTask() {
      let targetList = []
      this.checkedList.forEach(item => {
        if (item.stageName.split('-').length > 1) {
          targetList.push({
            stageId: item.stageId,
            priority:
              item.stageName.split('-')[1] == '高'
                ? 'HIGH'
                : item.stageName.split('-')[1] == '普通'
                ? 'MIDDLE'
                : item.stageName.split('-')[1] == '低'
                ? 'LOW'
                : ''
          })
        } else {
          targetList.push({ stageId: item.stageId, priority: 'MIDDLE' })
        }
      })
      if (!this.checkedList?.length) return
      this.handleTasks(targetList)
    },
    onChange(e, item) {
      if (
        e.target.checked &&
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      } else if (
        !e.target.checked &&
        this.checkedList.some(i => {
          return i.stageId == item.stageId
        })
      ) {
        this.checkedList = this.checkedList.filter(i => {
          return i.stageId != item.stageId
        })
      }
    },
    openManuscriptList() {
      let arr = []
      this.selectionRows.forEach(async item => {
        const res = await getAction('/production/get_guide', {
          current: 1,
          fieId: 'guidName',
          id: item.productionId,
          size: -1,
          typeId: 1
        })
        if (res.code == 200) {
          let isPass = true
          if (res.data.records.filter(item => item.classId == 4).length > 1) {
            this.$refs.fontModal.openModal(res.data.records.filter(item => item.classId == 4))
            isPass = false
          }
          arr.push(isPass)
        } else {
          this.$message.error(res.msg)
        }
      })
      if (arr.every(a => a)) {
        this.$refs.manuscript.open()
      }
    },
    openSumbmitList() {
      let arr = []
      this.selectionRows.forEach(async item => {
        const res = await getAction('/production/get_guide', {
          current: 1,
          fieId: 'guidName',
          id: item.productionId,
          size: -1,
          typeId: 1
        })
        if (res.code == 200) {
          let isPass = true
          if (res.data.records.filter(item => item.classId == 4).length > 1) {
            this.$refs.fontModal.openModal(res.data.records.filter(item => item.classId == 4))
            isPass = false
          }
          arr.push(isPass)
        } else {
          this.$message.error(res.msg)
        }
      })
      if (arr.every(a => a)) {
        this.$refs.sumbmitModal.open()
      }
    },
    setUsers(userIds) {
      this.userIds = userIds
      if (this.userIds.length) {
        this.userIds.forEach(i => {
          if (this.searchUsers.every(item => item.userId != i)) {
            this.searchUsers.push(this.allUsers.find(item => item.userId == i))
          }
        })
      }
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = [...this.searchUsers, ...this.allUsers.filter(item => item.userName.indexOf(value) > -1)]
        this.searchUsers = [...new Set(this.searchUsers)]
      } else {
        this.searchUsers = []
      }
    },
    getTaskStatus(taskStatus) {
      let text =
        taskStatus == 'FINISHED'
          ? '已完成'
          : taskStatus == 'STARTED'
          ? '可开始'
          : taskStatus == 'WAITING'
          ? '等待'
          : taskStatus == 'REJECTING'
          ? '驳回'
          : ''
      return text
    },
    setRowkeys(records) {
      records = records.filter(item => !item.hasChild)
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
      this.selectionRows?.forEach(item => {
        if (this.getTaskStatus(item.taskStatus)) {
          item.taskStatus = this.getTaskStatus(item.taskStatus)
        }
      })
      console.log(this.selectionRows)
      this.rejectShow = this.selectionRows.every(item => item.stageName == '甲方反馈') ? false : true
    },
    workChangeEdit(type) {
      /* if (type == 'day') {
        this.workDayValueShow = true
        this.$nextTick(() => {
          this.$refs.workSelect.focus()
        })
      } else {
        this.workDayNumValueShow = true
        this.$nextTick(() => {
          this.$refs.workInput.focus()
        })
      } */
      if (
        this.workDays.some(item => {
          return item == -1
        })
      ) {
        this.selectDis = true
        this.workDays = [-1]
        this.scheduleIsDaily = 1
      }
      this.workVisible = true
    },
    async saveFilter() {
      this.filterLoading = true
      this.funcShow = false
      const data = {
        name: 'check',
        content: JSON.stringify({
          checkedFuncList: this.checkedFuncList,
          checkedStatusList: this.checkedStatusList
        }),
        domain: 'myTsskFunc',
        type: 'FILTER'
      }
      await postAction('/fontQueryCondition', [data])
      await deleteAction(
        '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: [this.checkedData.id || ''] })).toString()
      )
      this.filterCheckList = [...this.checkedFuncList, ...this.checkedStatusList]
      this.statusPopoverVis = false
      if (
        JSON.stringify(this.checkedFuncList) != JSON.stringify(JSON.parse(this.checkedData.content).checkedFuncList)
      ) {
        window.location.reload()
      } else {
        this.$refs.calendarPanel.reloadData()
      }
    },
    checkChange(checkedList, type) {
      this.funcShow = true
      let statusOptions = ['已完成', '可开始', '等待', '驳回']
      let funcOptions = ['排期', '节假日', '可安排数量']
      if (type == 'status') {
        this.indeterminateStatuts = !!checkedList.length && checkedList.length < statusOptions.length
        this.checkAllStatus = checkedList.length === statusOptions.length
      } else {
        this.indeterminateFunc = !!checkedList.length && checkedList.length < funcOptions.length
        this.checkAllFunc = checkedList.length === funcOptions.length
      }
    },
    onCheckAllChange(e, type) {
      let statusOptions = ['已完成', '可开始', '等待', '驳回']
      let funcOptions = []
      funcOptions = ['排期', '节假日', '可安排数量']
      if (type == 'status') {
        Object.assign(this, {
          checkedStatusList: e.target.checked ? statusOptions : [],
          indeterminateStatuts: false,
          checkAllStatus: e.target.checked
        })
      } else {
        Object.assign(this, {
          checkedFuncList: e.target.checked ? funcOptions : [],
          indeterminateFunc: false,
          checkAllFunc: e.target.checked
        })
      }
    },
    async getCheckList() {
      const res = await getAction('/fontQueryCondition', { domain: 'myTsskFunc', type: 'FILTER' })
      try {
        this.checkedData = res.data[res.data.length - 1] || this.checkedData
        if (res.data[res.data.length - 1]) {
          this.checkedFuncList = JSON.parse(this.checkedData.content).checkedFuncList
          this.checkedStatusList = JSON.parse(this.checkedData.content).checkedStatusList
          this.filterCheckList = [...this.checkedFuncList, ...this.checkedStatusList]
        } else {
          this.checkedFuncList = this.checkedData.checkedFuncList
          this.checkedStatusList = this.checkedData.checkedStatusList
          this.filterCheckList = [...this.checkedFuncList, ...this.checkedStatusList]
        }
        if (!this.checkedFuncList) this.checkedFuncList = ['排期', '节假日', '可安排数量']
        this.$forceUpdate()
      } catch (err) {}
    },
    async getCalendarType() {
      const res = await getAction('/fontQueryCondition', { domain: 'calendarPanel', type: 'FILTER' })
      try {
        let checkedData = res.data[res.data.length - 1] || { viewType: 'dayGridMonth' }
        this.deleViewTypes = res.data?.map(item => item.id)
        if (res.data[res.data.length - 1]) {
          this.calendarType = JSON.parse(checkedData.content).viewType
          if (this.$refs.calendarPanel) {
            this.$refs.calendarPanel.viewType = this.calendarType
            this.$nextTick(() => {
              if (this.calendarType == 'dayGridMonth') {
                this.$refs.calendarPanel.monthClick()
              } else if (this.calendarType == 'dayGridWeek') {
                this.$refs.calendarPanel.weekClick()
              } else if (this.calendarType == 'dayGridDay') {
                this.$refs.calendarPanel.dayClick()
              }
            })
          }
        }
      } catch (err) {}
    },
    async openStatusPop() {
      this.filterLoading = true
      this.statusPopoverVis = true
      let statusOptions = ['已完成', '可开始', '等待', '驳回']
      let funcOptions = []
      funcOptions = ['排期', '节假日', '可安排数量']
      const res = await getAction('/fontQueryCondition', { domain: 'myTsskFunc', type: 'FILTER' })
      this.checkedData = res.data[res.data.length - 1] || this.checkedData
      if (res.data[res.data.length - 1]) {
        this.checkedFuncList = JSON.parse(this.checkedData.content).checkedFuncList
        this.checkedStatusList = JSON.parse(this.checkedData.content).checkedStatusList
      } else {
        this.checkedFuncList = this.checkedData.checkedFuncList
        this.checkedStatusList = this.checkedData.checkedStatusList
      }
      this.indeterminateFunc = this.checkedFuncList.length < funcOptions.length
      this.checkAllFunc = funcOptions.length === this.checkedFuncList.length
      this.indeterminateStatuts = this.checkedStatusList.length < statusOptions.length
      this.checkAllStatus = statusOptions.length === this.checkedStatusList.length
      this.filterLoading = false
    },
    getWorkStyle() {
      let text = 'color:rgba(0,0,0,0.65)'
      if (!this.dateInfo) return text
      if (!this.isSleep) {
        return 'color:' + this.color
      }
      if (this.isHoliDay) {
        return 'color:rgba(0,0,0,0.25);cursor:no-drop;'
      }
    },
    radioChange(e) {},
    async getFilterStageList() {
      const userIds = this.userIds.length ? JSON.parse(JSON.stringify(this.userIds)) : [this.userInfo.id]
      setTimeout(() => {
        getAction(
          '/stage/stageByUserIds?' + new URLSearchParams(handleArrayParams({ userIds: userIds })).toString()
        ).then(res => {
          if (res.code == 200) {
            this.filterStageList = res.data
          }
        })
        getAction(
          '/platform/listByUserIds?' + new URLSearchParams(handleArrayParams({ userIds: userIds })).toString()
        ).then(rest => {
          if (rest.code == 200) {
            this.filterPlatformList = rest.data
          }
        })
      }, 500)
    },
    filterListPush(item) {
      JSON.parse(item.content).userIds && (this.userIds = JSON.parse(item.content).userIds)
      JSON.parse(item.content).stageIds && (this.stageIds = JSON.parse(item.content).stageIds)
      JSON.parse(item.content).platformIds && (this.platformIds = JSON.parse(item.content).platformIds)
      this.productionName = JSON.parse(item.content).productionName || ''
      this.productSequenceNo = JSON.parse(item.content).productSequenceNo || null
      JSON.parse(item.content).selTaskStatus && (this.selTaskStatus = JSON.parse(item.content).selTaskStatus)
      JSON.parse(item.content).hasNoteStatus && (this.hasNoteStatus = JSON.parse(item.content).hasNoteStatus)
      JSON.parse(item.content).finishStatus && (this.finishStatus = JSON.parse(item.content).finishStatus)
      this.userIds.forEach(i => {
        if (this.allUsers.find(item => item.userId == i)) {
          this.searchUsers.push(this.allUsers.find(item => item.userId == i))
        }
      })
      this.searchUsers = [...new Set(this.searchUsers)]
    },
    async removeFilter() {
      const { id } = Vue.ls.get(USER_INFO) || {}
      /* this.userIds = [id] */
      this.userIds = []
      this.stageIds = []
      this.platformIds = []
      this.productionName = ''
      this.productSequenceNo = ''
      this.selTaskStatus = []
      this.hasNoteStatus = '2'
      this.finishStatus = []
    },
    async addFilter() {
      if (this.filterList.every(item => item.name != this.filterName)) {
        this.filterLoading = true
        const data = {
          name: this.filterName,
          content: JSON.stringify({
            userIds: this.userIds.length ? this.userIds : [this.userInfo.id],
            stageIds: this.stageIds,
            platformIds: this.platformIds,
            productionName: this.productionName,
            productSequenceNo: this.productSequenceNo,
            selTaskStatus: this.selTaskStatus,
            hasNoteStatus: this.hasNoteStatus,
            finishStatus: this.finishStatus
          }),
          domain: 'myTssk',
          type: 'FILTER'
        }
        await postAction('/fontQueryCondition', [data])
        const res = await getAction('/fontQueryCondition', { domain: 'myTssk', type: 'FILTER' })
        this.filterList = res.data || []
        this.filterLoading = false
      } else {
        this.$message.error('收藏名称重复')
      }
    },
    async openPop() {
      this.filterLoading = true
      const res = await getAction('/fontQueryCondition', { domain: 'myTssk', type: 'FILTER' })
      this.filterList = res.data || []
      this.popoverVis = true
      this.filterLoading = false
    },
    async getFilterTypes() {},
    async starClick(item) {
      this.filterLoading = true
      await deleteAction(
        '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: [item.id || ''] })).toString()
      )
      const res = await getAction('/fontQueryCondition', { domain: 'myTssk', type: 'FILTER' })
      this.filterList = res.data || []
      this.filterLoading = false
    },
    compute(item, menuIndex, menuText) {
      if (!this.selectionRows.length) {
        this.$message.warning('请先勾选数据')
        return
      }
      if (menuText == '求和') {
        item.total = 0
        this.selectionRows.forEach(i => {
          item.total = item.total + (i[item.dataIndex] - 0)
        })
        console.log(this.$refs['statistics-' + menuIndex])
        this.$refs['statistics-' + menuIndex][0].style.opacity = '1'
      } else if (menuText == '平均') {
        let total = 0
        this.selectionRows.forEach(i => {
          total = total + (i[item.dataIndex] - 0)
        })
        item.total = (total / this.selectionRows.length).toFixed(2)
        this.$refs['statistics-' + menuIndex][0].style.opacity = '1'
      }
    },
    handleScroll(e) {
      document.getElementsByClassName('tabel-result-footer')[0].scrollLeft = e.target.scrollLeft
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      if (this.$refs.calendarPanel) {
        this.$refs.calendarPanel.reloadData()
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          this.dataSource.forEach(item => {
            item.totalScore = ''
            item.evalResult = {
              kpiValueErrors: []
            }
          })
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    toOtherCalender(e) {
      if (e) window.open(`/system/otherTasks/${e}?&name=他人日历`)
    },
    prevClick() {
      this.$refs.calendarPanel.prevClick()
    },
    nextClick() {
      this.$refs.calendarPanel.nextClick()
    },
    async changeViewTypeToWeek(type) {
      this.calendarType = type
      this.$refs.calendarPanel.handleSelectOnTwo()
      this.isCalendarCancel = false
      this.isEditSelect = false
      this.selectKeys = []
      if (type == 'dayGridWeek') {
        this.isWeek = true
      } else {
        this.isWeek = false
      }
      const data = {
        name: 'calendarPanel',
        content: JSON.stringify({
          viewType: this.$refs.calendarPanel.viewType || 'dayGridMonth'
        }),
        domain: 'calendarPanel',
        type: 'FILTER'
      }
      await postAction('/fontQueryCondition', [data])
      await deleteAction(
        '/fontQueryCondition?' +
          new URLSearchParams(
            handleArrayParams({ ids: this.deleViewTypes.filter((item, index) => index < 200) })
          ).toString()
      ) //清除多余数据，下周修改
    },
    setSkipLoading(skipLoading) {
      this.skipLoading = skipLoading
    },
    backSkip() {
      this.isSkip = false
      this.isAdmin = false
      const { positions, post, roles, userFile, id } = Vue.ls.get(USER_INFO) || {}
      this.queryParam.userIds = id
      this.userIds = [id]
      if (Array.isArray(positions)) {
        positions.forEach(item => {
          if (item.code === post) {
            this.name = item.name
          }
        })
      }
      if (roles) {
        roles.forEach(one => {
          if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
            this.isAdmin = true
          }
          if (one.roleCode == 'tongchou') {
            this.isTongchou = true
          }
        })
      }
      if (!this.isAdmin) {
        this.workdayAvailableCount = userFile.workdayAvailableCount - 0
        this.workDays = userFile.workDays.sort((a, b) => {
          return a - b
        })
      }
      this.getAllUsers()
      this.getStageList()
      getAction('/sys/user/get_user_file', { userId: this.queryParam.userIds })
        .then(res => {
          if (res.success) {
            if (!this.isAdmin) {
              this.workdayAvailableCount = res.data.workdayAvailableCount - 0
              this.workDays = res.data.workDays.sort((a, b) => {
                return a - b
              })
              this.$nextTick(() => {
                if (this.$refs.calendarPanel) this.$refs.calendarPanel.addDayTitle()
              })
            } else {
              this.$nextTick(() => {
                if (this.$refs.calendarPanel) this.$refs.calendarPanel.addAdminDayTitle()
              })
            }
          }
        })
        .catch(err => {})
    },
    setQueryParam(info) {
      this.queryParam.userIds = info.id
      this.userIds = [info.id]
      this.isSkip = info.isSkip
      getAction('/sys/user/get_user_file', { userId: this.queryParam.userIds })
        .then(res => {
          if (res.success) {
            this.isAdmin = info.type
            /* this.$refs.calendarPanel.isShow = false */
            if (!this.isAdmin) {
              this.workdayAvailableCount = res.data.workdayAvailableCount - 0
              this.workDays = res.data.workDays.sort((a, b) => {
                return a - b
              })
              this.$nextTick(() => {
                if (this.$refs.calendarPanel) this.$refs.calendarPanel.addDayTitle()
              })
            } else {
              this.$nextTick(() => {
                if (this.$refs.calendarPanel) this.$refs.calendarPanel.addAdminDayTitle()
              })
            }
          }
        })
        .catch(err => {})
    },
    setIsWorkEdit() {
      this.isWorkEdit = false
      let userInfo = Vue.ls.get(USER_INFO) || {}
      this.workDays = userInfo.userFile.workDays.sort((a, b) => {
        return a - b
      })
      this.workdayAvailableCount = userInfo.userFile.workdayAvailableCount - 0
    },
    async workEdit() {
      let userInfo = Vue.ls.get(USER_INFO) || {}
      if (
        JSON.stringify(userInfo.userFile.workDays) == JSON.stringify(this.workDays) &&
        userInfo.userFile.workdayAvailableCount == this.workdayAvailableCount
      ) {
        this.workDayValueShow = false
        this.workDayNumValueShow = false
        return
      }
      userInfo.userFile.workDays = this.workDays.sort((a, b) => {
        return a - b
      })
      userInfo.userFile.workdayAvailableCount = this.workdayAvailableCount || 0
      let n1 = userInfo.userFile.workdayAvailableCount || 0
      if (userInfo.userFile.weekendAvailableCount !== undefined) {
        if (
          userInfo.userFile.workDays.every(item => {
            return item != 6 && item != 7 && item != 8
          })
        ) {
          userInfo.userFile.weekendAvailableCount = 0
        }
      }
      let n2 = userInfo.userFile.weekendAvailableCount || 0
      let arr = userInfo.userFile.workDays?.filter(item => {
        return item != 8
      })
      let length = arr ? arr.length : 0
      userInfo.userFile.weekAvailableCount = n1 * length + n2

      await putAction('/sys/user/edit_user_file', userInfo)
      try {
        this.$message.success('保存成功')
        window.location.reload(true)
        this.isWorkEdit = !this.isWorkEdit
      } catch (err) {
        console.log(err)
      }
      /*       if (!this.isWorkEdit) {
        this.isWorkEdit = !this.isWorkEdit
      } else {
        let userInfo = Vue.ls.get(USER_INFO) || {}
        userInfo.userFile.workDays = this.workDays
        userInfo.userFile.workdayAvailableCount = this.workdayAvailableCount || 0
        let n1 = userInfo.userFile.workdayAvailableCount || 0
        if (userInfo.userFile.weekendAvailableCount !== undefined) {
          if (
            userInfo.userFile.workDays.every(item => {
              return item != 6 && item != 7 && item != 8
            })
          ) {
            userInfo.userFile.weekendAvailableCount = 0
          }
        }
        let n2 = userInfo.userFile.weekendAvailableCount || 0
        let arr = userInfo.userFile.workDays?.filter(item => {
          return item != 8
        })
        let length = arr ? arr.length : 0
        userInfo.userFile.weekAvailableCount = n1 * length + n2

        await putAction('/sys/user/edit_user_file', userInfo)
        try {
          this.$message.success('保存成功')
          window.location.reload(true)
          this.isWorkEdit = !this.isWorkEdit
        } catch (err) {
          console.log(err)
        }
      } */
    },
    getTitle() {
      if (document.getElementsByClassName('fc-toolbar-title')[0]) {
        this.calendarDate = document.getElementsByClassName('fc-toolbar-title')[0].innerText || ''
      }
      this.$nextTick(() => {
        let date = new Date()
        var Y = date.getFullYear() + ''
        var M = date.getMonth() + 1
        this.nowDate = Y + '年' + M + '月'
      })
    },
    kpiViewList(evalResult) {
      if (!evalResult) return []
      return JSON.parse(evalResult)
      /* return Object.entries(errorTypeScoreMap)
        .map(([type, score]) => ({ type, score }))
        .filter(i => i.score > 0) */
    },
    getdiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array()
      var i = 0
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime

        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime()
        console.log('当前日期：' + stime + '当前时间戳：' + stime_ts)

        //增加一天时间戳后的日期
        var next_date = stime_ts + 24 * 60 * 60 * 1000

        //拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + '-'
        var next_dates_m =
          new Date(next_date).getMonth() + 1 < 10
            ? '0' + (new Date(next_date).getMonth() + 1) + '-'
            : new Date(next_date).getMonth() + 1 + '-'
        var next_dates_d =
          new Date(next_date).getDate() < 10 ? '0' + new Date(next_date).getDate() : new Date(next_date).getDate()

        stime = next_dates_y + next_dates_m + next_dates_d

        //增加数组key
        i++
      }
      console.log(diffdate)
      return diffdate
    },
    getDate(info) {
      this.dateInfo = info.dateInfo
      this.isWorkDay = info.isWorkDay
      this.isHoliDay = info.isHoliDay
      this.isSleep = info.isSleep
      this.isAllDis = info.isAllDis
      let selectDays = this.generateDatesBetween(this.dateInfo.startStr, this.getEndDate(this.dateInfo.end))
      if (!this.isWorkDay) {
        this.pushWorkDays.forEach(d => {
          if (selectDays.some(pd => d == pd)) {
            this.isWorkDay = true
            this.isHoliDay = false
          }
        })
      }
      console.log({ info }, this.pushWorkDays, selectDays)
    },
    async getWorkDate() {
      const res = await postAction('/work/overtime/list', { userId: this.userInfo.id })
      if (res.code == 200) {
        this.pushWorkDays = []
        res.data?.forEach(item => {
          if (this.pushWorkDays.every(d => d != item.date)) {
            this.pushWorkDays.push(item.date)
          }
        })
      }
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始，所以需要+1
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    generateDatesBetween(startDateStr, endDateStr) {
      const startDate = new Date(startDateStr)
      const endDate = new Date(endDateStr)
      const dates = []

      let currentDate = startDate

      while (currentDate <= endDate) {
        // 将当前日期添加到数组中
        dates.push(this.formatDate(currentDate))

        // 增加一天
        currentDate.setDate(currentDate.getDate() + 1)
      }

      return dates
    },
    getEndDate(time) {
      var currentDate = time

      // 减去一天
      currentDate.setDate(currentDate.getDate() - 1)

      // 格式化为 YYYY-MM-DD
      var year = currentDate.getFullYear()
      var month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      var day = currentDate
        .getDate()
        .toString()
        .padStart(2, '0')

      var formattedDate = year + '-' + month + '-' + day
      currentDate.setDate(currentDate.getDate() + 1)
      return formattedDate
    },
    async setVacation(value) {
      console.log(value, this.isWorkDay, 123)
      debugger
      if (value != 'WORK') {
        let dateArr = this.getdiffdate(this.dateInfo.startStr, this.dateInfo.endStr)
        dateArr.pop()
        if (this.$refs.calendarPanel && dateArr && dateArr.length) {
          let checkArr = this.$refs.calendarPanel.allList.filter(item => dateArr.some(d => item.start.indexOf(d) > -1))
          if (checkArr.length) return this.$message.warning('被选中日期已有任务，请联系统筹修改任务截止日后再操作')
        }
      }

      this.vacation = value
      if (!this.dateInfo) return
      if (this.vacation == '0') {
        const res = await postAction('/workSchedule/', {
          startDate: this.dateInfo.startStr,
          endDate: this.dateInfo.endStr,
          type: this.vacation == '0' ? null : this.vacation,
          userIds: this.userIds.length ? this.userIds : [this.userInfo.id]
        })
        if (res.code == 200) {
          this.$message.success('修改成功')
          /*           if (!this.isWorkDay) {
            if (this.getEndDate(this.dateInfo.end) == this.dateInfo.startStr) {
              this.workModalVisible = true
              this.workDates = []
              this.labelList
                .filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item =>
                      item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                )
                ?.forEach(item => {
                  this.workDates.push({
                    date: this.dateInfo.startStr,
                    value: 0,
                    dp: item.departId + '-' + item.positionId
                  })
                })
            } else {
              this.workModalVisible = true
              let workDates = this.generateDatesBetween(this.dateInfo.startStr, this.getEndDate(this.dateInfo.end))
              this.workDates = []
              this.labelList
                .filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item =>
                      item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                )
                ?.forEach(item => {
                  workDates?.forEach(workDate => {
                    this.workDates.push({ date: workDate, value: 0, dp: item.departId + '-' + item.positionId })
                  })
                })
            }
          } */
          this.$refs.calendarPanel.reloadWorkDay(res.data)
          this.vacation = undefined
          this.dateInfo = null
          this.$refs.calendarPanel.reloadData()
          this.$refs.calendarPanel.schedule = []
        }
      } else {
        if (!this.isWorkDay) {
          if (this.getEndDate(this.dateInfo.end) == this.dateInfo.startStr) {
            /* this.$warning({
              title: `请填入${this.dateInfo.startStr}可安排数量。注意：未填入数值无法安排任务`
            }) */
            this.workDates = []
            this.labelList
              .filter(
                item =>
                  item.week == 'WORK_DAY' &&
                  (item =>
                    item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
              )
              ?.forEach(item => {
                this.workDates.push({
                  date: this.dateInfo.startStr,
                  value: 0,
                  dp: item.departId + '-' + item.positionId
                })
              })
            this.workModalVisible = true
          } else {
            /* this.$warning({
                title: `请填入${this.dateInfo.startStr}到${this.getEndDate(
                  this.dateInfo.end
                )}可安排数量。注意：未填入数值无法安排任务`
              }) */
            let workDates = this.generateDatesBetween(this.dateInfo.startStr, this.getEndDate(this.dateInfo.end))
            this.workDates = []
            this.labelList
              .filter(
                item =>
                  item.week == 'WORK_DAY' &&
                  (item =>
                    item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
              )
              ?.forEach(item => {
                workDates?.forEach(workDate => {
                  this.workDates.push({ date: workDate, value: 0, dp: item.departId + '-' + item.positionId })
                })
              })
            this.workModalVisible = true
            console.log(this.workDates)
          }
        }
        if (this.vacation != 'WORK') {
          this.noWrokVisible = true
          this.noWorkReasson = ''
        }

        /*         const res = await postAction('/workSchedule/', {
          applyStartTime: this.dateInfo.startStr,
          applyEndTime: this.dateInfo.endStr,
          type: this.vacation == '0' ? null : this.vacation,
          applyReason: this.noWorkReasson,
          applyUserId: this.userIds.length ? this.userIds[0] : this.userInfo.id
        })
        if (res.code == 200) {
          this.$message.success('申请成功')
        } */
      }
    },
    workModalCancel() {
      this.workModalVisible = false
      this.workDates = []
    },
    async workModalOk() {
      this.workModalVisible = false
      let params = []
      this.workDates.forEach(workDate => {
        params.push({
          userId: this.userInfo.id,
          departId: workDate.dp.split('-')[0],
          positionId: workDate.dp.split('-')[1],
          count: workDate.value,
          date: workDate.date
        })
      })
      this.workDates = []
      const res = await postAction('/work/overtime/edit', params)
      if (res == 200) {
        this.$message.success('提交成功')
        this.$refs.calendarPanel.reloadData()
      }
    },
    handleNoWorkCancel() {
      this.noWrokVisible = false
      this.noWorkReasson = ''
    },
    async handleNoWorkOk() {
      const res = await postAction('/user/leave/save', {
        applyStartTime: this.dateInfo.startStr,
        applyEndTime: this.dateInfo.endStr,
        type: this.vacation == '0' ? null : this.vacation,
        applyReason: this.noWorkReasson,
        applyUserId: this.userIds.length ? this.userIds[0] : this.userInfo.id
      })
      if (res.code == 200) {
        this.$message.success('申请成功')
        this.handleNoWorkCancel()
      } else {
        this.$message.error(res.msg)
      }
    },
    workDaysChange() {
      if (
        this.workDays.some(item => {
          return item == -1
        })
      ) {
        this.selectDis = true
        this.workDays = [-1]
        this.scheduleIsDaily = 1
      } else {
        this.selectDis = false
      }
    },
    workCountChange() {},
    setSelectKeys() {
      this.selectKeys = []
      this.selectionRows = []
      this.selectedRowKeys = []
    },
    changeSelect() {
      this.$refs.calendarPanel.handleSelectOn()
      this.isCalendarCancel = !this.isCalendarCancel
      this.isEditSelect = false
      this.selectKeys = []
      this.selectionRows = []
      this.selectedRowKeys = []
    },
    changeUserSelect() {
      this.$refs.table.handleUserSelectOn()
      this.isEditSelect = false
    },
    changeEditSelect() {
      if (!this.selectionRows.length) return
      if (this.isEditSelect) {
        this.$refs.table.getTableData()
      }
      this.isEditSelect = !this.isEditSelect
      this.$refs.table.handleEditSelectOn(this.isEditSelect)
    },
    changeDropSelect() {
      this.$refs.calendarPanel.handleDropSelectOn()
      this.isCalendarDropCancel = !this.isCalendarDropCancel
      this.isEditSelect = false
    },
    async handleTasks(targetList) {
      this.visible = false
      let params = {
        operator: 'REJECTING',
        sourceTaskIds: [],
        targetList
      }
      if (this.selectionRows.length > 0) {
        params.sourceTaskIds = this.selectionRows.map(item => item.taskId)
      } else {
        params.sourceTaskIds = this.selectKeys.map(item => item.taskId)
      }
      const res = await postAction('/tasks/batch/updateStatus', params)
      if (res.code == 200) {
        this.$message.success('驳回成功')
        this.rejectStages = []
        this.selectionRows = []
        this.selectedRowKeys = []
        this.selectKeys = []
        Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
          item.checked = false
        })
        this.isCalendarCancel = false
        if (this.$refs.table) this.$refs.table.getTableData()
        if (this.$refs.calendarPanel) {
          this.$refs.calendarPanel.handleSelectOn()
          this.$refs.calendarPanel.changeStatusData(res.data)
        }
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async handleTaskFine(hasFeedback) {
      this.visible = false
      let params = { operator: 'FINISHED', sourceTaskIds: [] }
      if (this.selectionRows.length > 0) {
        params.sourceTaskIds = this.selectionRows.map(item => item.taskId)
        if (this.selectionRows.some(item => item.stageName == '甲方反馈')) {
          params.hasFeedback = hasFeedback
        }
      } else {
        params.sourceTaskIds = this.selectKeys.map(item => item.taskId)
        if (this.selectKeys.some(item => item.stageName == '甲方反馈')) {
          params.hasFeedback = hasFeedback
        }
      }
      let that = this
      if (params.hasFeedback === false) {
        this.$confirm({
          content: h => <div>请确认是否真的没有反馈</div>,
          okText: '是',
          cancelText: '否',
          async onOk() {
            this.skipLoading = true
            const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
            if (response.code == 200) {
              if (response.data.productionList && response.data.productionList.length) {
                that.$refs.termListModal.open(response.data.productionList)
              } else {
                const res = await postAction('/tasks/batch/updateStatus', params)
                this.skipLoading = false
                if (res.code == 200) {
                  that.rejectStages = []
                  that.selectionRows = []
                  that.selectedRowKeys = []
                  that.selectKeys = []
                  that.isCalendarCancel = false
                  Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                    item.checked = false
                  })
                  if (res.data.noUpdateTasks.length) {
                    that.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                  } else {
                    upstatusDownloadFile(params.sourceTaskIds)
                    that.$message.success('已完成')
                    if (that.$refs.table) that.$refs.table.getTableData()
                  }
                  if (that.$refs.calendarPanel && res.data.updatedTasks.length) {
                    that.$refs.calendarPanel.handleSelectOn()
                    that.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                  }
                } else {
                  that.$message.error(res.msg || res.message)
                }
              }
            }
          },
          onCancel() {},
          class: ''
        })
        return
      } else {
        if (
          (this.selectionRows.length &&
            this.selectionRows.some(
              item =>
                item.useTranslationSystem &&
                (item.stageName == '翻译' || item.stageName == '校对' || item.stageName == '终审')
            )) ||
          (this.selectKeys.length &&
            this.selectKeys.some(
              item =>
                item.useTranslationSystem &&
                (item.stageName == '翻译' || item.stageName == '校对' || item.stageName == '终审')
            ))
        ) {
          this.$confirm({
            content: h => <div>请确认当前任务的工作已经全部完成，提前点完成/未完成点完成均会影响后续考核。</div>,
            okText: '是',
            cancelText: '否',
            onOk: async () => {
              this.skipLoading = true
              const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
              if (response.code == 200) {
                if (response.data.productionList && response.data.productionList.length) {
                  this.$refs.termListModal.open(response.data.productionList)
                  this.skipLoading = false
                } else {
                  const res = await postAction('/tasks/batch/updateStatus', params)
                  this.skipLoading = false
                  if (res.code == 200) {
                    this.rejectStages = []
                    this.selectionRows = []
                    this.selectedRowKeys = []
                    this.selectKeys = []
                    this.isCalendarCancel = false
                    Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                      item.checked = false
                    })
                    /*         if (res.data.noKpiTasks) {
          res.data.noKpiTasks?.forEach(item => {
            this.$message.error(
              '【' + item.productionName + '-' + item.chapterSequenceNo + '】' + '对其他阶段考核未填写',
              5
            )
          })
          if (this.$refs.calendarPanel) {
            this.$refs.calendarPanel.handleSelectOn()
            this.$refs.calendarPanel.changeStatusData([])
          }
          return
        } */
                    if (res.data.noUpdateTasks.length) {
                      this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                    } else {
                      upstatusDownloadFile(params.sourceTaskIds)
                      this.$message.success('已完成')
                      if (this.$refs.table) this.$refs.table.getTableData()
                      if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
                    }
                    if (this.$refs.calendarPanel && res.data.updatedTasks.length) {
                      this.$refs.calendarPanel.handleSelectOn()
                      this.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                    }
                  } else {
                    this.$message.error(res.msg || res.message)
                  }
                }
              }
            }
          })
        } else {
          if (this.selectionRows.some(row => !row.finishManuscriptFileCount && row.stageName == '制作修改')) {
            let message = ''
            this.selectionRows.forEach(row => {
              if (!row.finishManuscriptFileCount && row.stageName == '制作修改') {
                let text ='【'+ row.productOrder + '-' + row.productionName + '-' + row.chapterOrder + '-' + row.stageName+'】'
                message = message + text
              }
            })
            this.$confirm({
              content: h => <div>{ message }修改稿数量为0，是否确认完成？</div>,
              okText: '是',
              cancelText: '否',
              onOk: async () => {
                this.skipLoading = true
                const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
                if (response.code == 200) {
                  if (response.data.productionList && response.data.productionList.length) {
                    this.$refs.termListModal.open(response.data.productionList)
                    this.skipLoading = false
                  } else {
                    const res = await postAction('/tasks/batch/updateStatus', params)
                    this.skipLoading = false
                    if (res.code == 200) {
                      this.rejectStages = []
                      this.selectionRows = []
                      this.selectedRowKeys = []
                      this.selectKeys = []
                      this.isCalendarCancel = false
                      Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                        item.checked = false
                      })
                      if (res.data.noUpdateTasks.length) {
                        this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                      } else {
                        upstatusDownloadFile(params.sourceTaskIds)
                        this.$message.success('已完成')
                        if (this.$refs.table) this.$refs.table.getTableData()
                        if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
                      }
                      if (this.$refs.calendarPanel && res.data.updatedTasks.length) {
                        this.$refs.calendarPanel.handleSelectOn()
                        this.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                      }
                    } else {
                      this.$message.error(res.msg || res.message)
                    }
                  }
                }
              }
            })
            return
          }
          this.skipLoading = true
          const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
          if (response.code == 200) {
            if (response.data.productionList && response.data.productionList.length) {
              this.$refs.termListModal.open(response.data.productionList)
              this.skipLoading = false
            } else {
              const res = await postAction('/tasks/batch/updateStatus', params)
              this.skipLoading = false
              if (res.code == 200) {
                this.rejectStages = []
                this.selectionRows = []
                this.selectedRowKeys = []
                this.selectKeys = []
                this.isCalendarCancel = false
                Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                  item.checked = false
                })
                /*         if (res.data.noKpiTasks) {
          res.data.noKpiTasks?.forEach(item => {
            this.$message.error(
              '【' + item.productionName + '-' + item.chapterSequenceNo + '】' + '对其他阶段考核未填写',
              5
            )
          })
          if (this.$refs.calendarPanel) {
            this.$refs.calendarPanel.handleSelectOn()
            this.$refs.calendarPanel.changeStatusData([])
          }
          return
        } */
                if (res.data.noUpdateTasks.length) {
                  this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                } else {
                  upstatusDownloadFile(params.sourceTaskIds)
                  this.$message.success('已完成')
                  if (this.$refs.table) this.$refs.table.getTableData()
                  if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
                }
                if (this.$refs.calendarPanel && res.data.updatedTasks.length) {
                  this.$refs.calendarPanel.handleSelectOn()
                  this.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                }
              } else {
                this.$message.error(res.msg || res.message)
              }
            }
          }
        }
      }
    },
    closeTasksModel(data) {
      if (!data) {
        if (this.$refs.table) this.$refs.table.getTableData()
        if (this.$refs.calendarPanel) this.$refs.calendarPanel.handleSelectOn()
        if (this.$refs.sumbmitModal) {
          this.$refs.sumbmitModal.getTableData()
        }
        return
      }
      this.rejectStages = []
      this.selectionRows = []
      this.selectedRowKeys = []
      this.selectKeys = []
      this.isCalendarCancel = true
      Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
        item.checked = false
      })
      if (this.$refs.table) this.$refs.table.getTableData()
      if (this.$refs.calendarPanel && data.updatedTasks.length) {
        this.$refs.calendarPanel.handleSelectOn()
        this.$refs.calendarPanel.changeStatusData(data.updatedTasks)
      }
    },
    getTaskTableData() {
      this.$refs.table.getTableData()
      this.selectionRows = []
    },
    visibleChange() {
      setTimeout(() => {
        this.visible = !this.visible
      }, 100)
    },
    async getRejectStages() {
      let stageId = ''
      let productId = ''
      if (this.viewType == 'list') {
        this.rejectShow = this.selectionRows.every(item => item.stageName == '甲方反馈') ? false : true
        stageId = this.selectionRows.filter(item => item.chapterSortAndStageName)[0].stageId
        productId = this.selectionRows.filter(item => item.chapterSortAndStageName)[0].productionId
        if (
          this.selectionRows?.some(item => item.productionName != this.selectionRows[0].productionName) ||
          this.selectionRows?.some(item => item.stageName != this.selectionRows[0].stageName)
        ) {
          return
        }
      } else {
        stageId = this.selectKeys[0].stageId
        productId = this.selectKeys[0].productionId
        this.rejectShow = this.selectKeys.every(item => item.stageName == '甲方反馈') ? false : true
        if (
          this.selectKeys?.some(item => item.productionName != this.selectKeys[0].productionName) ||
          this.selectKeys?.some(item => item.stageName != this.selectKeys[0].stageName)
        ) {
          return
        }
      }
      const res = await getAction('/production/canRejectStage', { stageId, productId })
      try {
        if (res.code == 200) {
          this.rejectStages = res.data
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    getKey(data) {
      this.rejectStages = []
      /* if (this.$refs.calendarPanel.viewType == 'dayGridDay') return */
      if (data.checked) {
        if (this.selectKeys.findIndex(item => item.id == data.id) == -1) {
          this.selectKeys.push(data)
        }
      } else {
        if (this.selectKeys.findIndex(item => item.id == data.id) > -1) {
          this.selectKeys.splice(
            this.selectKeys.findIndex(item => item.id == data.id),
            1
          )
        }
      }
      this.selectKeys?.forEach(item => {
        item.taskName = item.stageName
      })
      console.log(this.selectKeys, 123)
      this.selectionRows = this.selectKeys
      this.rejectShow = this.selectKeys.every(item => item.stageName == '甲方反馈') ? false : true
    },
    changeFinishDis(type) {
      this.finishDis = type
    },
    handleLinkTranslateLocalDownPage() {
      createAlink(this.translateMyLocalDownPage, '_blank')
    },
    handleLinkTranslateUploadServerPage() {
      createAlink(this.translateUploadserver, '_blank')
    },
    handleLinkTranslateMyDowntasks() {
      createAlink(this.translateMyDowntasksDetialPath, '_blank')
    },
    handleLinkTranslateMytasks() {
      createAlink(this.translateMytasksDetialPath, '_blank')
      // this.$router.path({ path: this.translateMytasksDetialPath })
    },
    handleLinkTasks(record) {
      if (record.stageName === '给稿') {
        this.$router.push({
          path: `${this.mytasksUploadPath}/${record.taskId}/${record.productionId}/${record.chapterId}`,
          query: {
            record: encodeURIComponent(JSON.stringify(record))
          }
        })
      } else {
        this.$router.push({
          path: `${this.translateEditorPath}/${record.taskId}/${record.productionId}/${record.chapterId}/${record.platformId}`
        })
      }
    },
    getAllUsers() {
      getAction('/sys/sysDepart/userListToSelect').then(res => {
        this.allUsers = Object.assign([], res.data)
        /* this.searchUsers=this.allUsers.filter(item=>item.userId==this.userInfo.id) */
        const { id } = Vue.ls.get(USER_INFO) || {}
        /* this.userIds = [id] */
        this.searchQueryForm()
      })
    },
    setProductName(productName) {
      this.productionName = productName
    },
    async searchAndSaveQueryForm() {
      if (this.userIds.length) {
        this.queryParam.userIds = this.userIds.join(',')
      } else {
        this.queryParam.userIds = ''
      }
      if (this.stageIds.length) {
        this.queryParam.stageIds = this.stageIds.join(',')
      } else {
        this.queryParam.stageIds = ''
      }
      if (this.platformIds.length) {
        this.queryParam.platformIds = this.platformIds.join(',')
      } else {
        this.queryParam.platformIds = ''
      }
      if (this.selTaskStatus.length) {
        this.queryParam.taskStatusList = this.selTaskStatus
      } else {
        this.queryParam.taskStatusList = []
      }
      if (this.finishStatus.length) {
        this.queryParam.finishStatusList = this.finishStatus
      } else {
        this.queryParam.finishStatusList = []
      }
      this.queryParam.productionName = this.productionName
      this.queryParam.productSequenceNo = this.productSequenceNo
      this.queryParam.hasNoteStatus = this.hasNoteStatus
      if (this.viewType == 'calendar') {
        this.queryParam.needKpiView = false
        this.popoverVis = false
        const data = {
          name: 'myTaskSearch',
          content: JSON.stringify({
            userIds: this.userIds.length ? this.userIds : [],
            stageIds: this.stageIds,
            platformIds: this.platformIds,
            productionName: this.productionName,
            productSequenceNo: this.productSequenceNo,
            selTaskStatus: this.selTaskStatus,
            hasNoteStatus: this.hasNoteStatus,
            finishStatus: this.finishStatus
          }),
          domain: 'myTsskSearch',
          type: 'FILTER'
        }
        await postAction('/fontQueryCondition', [data])
        let ids = []
        if (this.deleteSearchIds.length > 200) {
          ids = this.deleteSearchIds.filter((item, index) => index < 200)
        } else {
          if (this.deleteSearchIds[this.deleteSearchIds.length - 1]) {
            ids = [this.deleteSearchIds[this.deleteSearchIds.length - 1]]
          }
        }
        if (ids.length) {
          await deleteAction('/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids })).toString())
        }

        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
    },
    async getSearchQueryForm() {
      const res = await getAction('/fontQueryCondition', { domain: 'myTsskSearch', type: 'FILTER' })
      try {
        let data = res.data[res.data.length - 1] || null
        this.deleteSearchIds = res.data?.map(item => item.id)
        if (data) {
          getAction('/sys/sysDepart/userListToSelect').then(res => {
            this.allUsers = Object.assign([], res.data)
            this.userIds = JSON.parse(data.content).userIds || []
            this.stageIds = JSON.parse(data.content).stageIds || []
            this.platformIds = JSON.parse(data.content).platformIds || []
            this.selTaskStatus = JSON.parse(data.content).selTaskStatus || []
            this.finishStatus = JSON.parse(data.content).finishStatus || []
            this.productionName = JSON.parse(data.content).productionName || ''
            this.productSequenceNo = JSON.parse(data.content).productSequenceNo || ''
            this.hasNoteStatus = JSON.parse(data.content).hasNoteStatus || '2'
            if (this.userIds.length) {
              this.userIds.forEach(i => {
                if (this.allUsers.find(item => item.userId == i)) {
                  this.searchUsers.push(this.allUsers.find(item => item.userId == i))
                }
              })
            }
            this.searchQueryForm()
          })
        } else {
          this.getAllUsers()
        }
      } catch (err) {}
    },
    searchQueryForm(data) {
      if (this.userIds.length) {
        this.searchUsers = []
        this.userIds.forEach(i => {
          if (this.allUsers.find(item => item.userId == i)) {
            this.searchUsers.push(this.allUsers.find(item => item.userId == i))
          }
        })
        this.queryParam.userIds = this.userIds.join(',')
      } else {
        this.queryParam.userIds = ''
      }
      if (this.stageIds.length) {
        this.queryParam.stageIds = this.stageIds.join(',')
      } else {
        this.queryParam.stageIds = ''
      }
      if (this.platformIds.length) {
        this.queryParam.platformIds = this.platformIds.join(',')
      } else {
        this.queryParam.platformIds = ''
      }
      if (this.selTaskStatus.length) {
        this.queryParam.taskStatusList = this.selTaskStatus
      } else {
        this.queryParam.taskStatusList = []
      }
      if (this.finishStatus.length) {
        this.queryParam.finishStatusList = this.finishStatus
      } else {
        this.queryParam.finishStatusList = []
      }
      this.queryParam.productionName = this.productionName
      this.queryParam.productSequenceNo = this.productSequenceNo
      this.queryParam.hasNoteStatus = this.hasNoteStatus
      if (this.viewType == 'list') {
        this.queryParam.needKpiView = true
        /* this.searchQuery() */

        if (this.isAdmin || (this.userInfo.roles && this.userInfo.roles.some(item => item.roleCode == 'zhuanjia'))) {
          this.userIds = data
          this.$nextTick(() => {
            this.$refs.table.saveUserIds()
          })
        } else {
          if (this.productionName) {
            this.$refs.table.productName = this.productionName
            this.$refs.table.inputFilter('name')
          } else {
            this.$refs.table.productName = ''
            this.$refs.table.inputFilter('name')
          }
        }
      } else if (this.viewType == 'calendar') {
        this.queryParam.needKpiView = false
        this.popoverVis = false
        this.$nextTick(() => {
          if (this.$refs.calendarPanel) this.$refs.calendarPanel.reloadData()
        })
      } else if (this.viewType == 'kanban') {
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
    },
    getStageList() {
      getAction('/stage/stageListToSelect').then(({ success, data }) => {
        this.stageList = data
      })
    },

    changeViewType(viewType) {
      this.isCalendarCancel = false
      this.isCalendarDropCancel = false
      this.rejectShow = false
      this.userIds = []
      if (this.viewType == 'calendar') {
        this.startDate = this.queryParam.startDate = ''
        this.endDate = this.queryParam.endDate = ''
      }
      if (viewType != 'list') {
        this.queryParam.needKpiView = false
        /* if (this.$refs.table) this.$refs.table.$el.querySelector('.ant-table-body').removeEventListener('scroll') */
      } else if (viewType == 'list') {
        this.queryParam.needKpiView = true
        /* this.$nextTick(() => {
          this.$refs.table.$el.querySelector('.ant-table-body').addEventListener('scroll', this.handleScroll)
        }) */
      }
      if (this.viewType != viewType && viewType == 'list') {
        /*  this.loadData() */
      }
      if (this.viewType == 'confirm-list' && viewType == 'calendar') {
        this.$nextTick(() => {
          this.$refs.calendarPanel.viewType = 'dayGridWeek'
          this.$refs.calendarPanel.reloadData()
        })
      }
      this.viewType = viewType
      this.selectKeys = []
      this.selectionRows = []
      if (viewType == 'calendar') {
        this.isView = false
        this.userIds = []
        this.getSearchQueryForm()
        if (!this.isAdmin) {
          this.$nextTick(() => {
            if (this.$refs.calendarPanel) this.$refs.calendarPanel.addDayTitle()
          })
        } else {
          this.$nextTick(() => {
            if (this.$refs.calendarPanel) this.$refs.calendarPanel.addAdminDayTitle()
          })
        }
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
      if (viewType == 'calendar-table') {
        this.viewType = 'calendar'
        this.isView = true
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
      if (viewType == 'confirm-list') {
        this.$nextTick(() => {
          if (this.$refs.confirmTable) this.$refs.confirmTable.getTableData()
        })
      }
      this.selectKeys = []
      this.selectedRowKeys = []
      this.$forceUpdate()
    },
    setSearchDate({ startDate, endDate }) {
      if (startDate) this.startDate = startDate
      if (endDate) this.endDate = endDate
      console.log('setSearchDate', { startDate, endDate })
      this.queryParam.startDate = startDate?.format('YYYY-MM-DD') || ''
      this.queryParam.endDate = endDate?.format('YYYY-MM-DD') || ''
      console.log({ 'this.queryParam': this.queryParam })
    },
    async getConfirmTableData() {
      if (this.$refs.confirmTable) {
        this.$refs.confirmTable.getTableData()
      } else {
        const res = await postAction('/task/confirm/list')
        this.confirmTableTotal = res.data.total || 0
      }
    }
  },
  mounted() {
    this.color = this.$store.state.app.color
    this.getFilterStageList()
    this.getConfirmTableData()
    setTimeout(() => {
      if (!this.isAdmin) this.getTitle()
    }, 50)
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
    const { positions, post, roles, userFile } = this.userInfo || {}
    this.formData = { ...this.userInfo }
    if (Array.isArray(positions)) {
      positions.forEach(item => {
        if (item.code === post) {
          this.name = item.name
        }
      })
    }
    if (roles) {
      roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
        if (one.roleCode == 'tongchou') {
          this.isTongchou = true
        }
      })
    }
    this.workdayAvailableCount = userFile.workdayAvailableCount - 0
    this.workDays =
      userFile.workDays.sort((a, b) => {
        return a - b
      }) || []
    this.getSearchQueryForm()
    this.getCheckList()
    this.getCalendarType()
    /* this.getAllUsers() */
    this.getStageList()
    this.getWorkNum()
    this.getWorkDate()
  }
}
</script>

<style lang="less" scoped>
.circular {
  display: inline-block;
  width: 10px;
  height: 10px;
  //   background-color: grey;
  border-radius: 100%;
  margin-right: 5px;
  cursor: pointer;
}
.calendar-menu {
  display: inline-block;
  font-size: 12px;

  > div {
    display: flex;
    align-items: center;

    &:hover {
      color: var(--theme-color);
      cursor: pointer;
    }

    > span {
      margin-left: 3px;
    }
  }
}

.work-left {
  font-size: 12px;
  margin-left: 1vw;
  display: flex;
  align-items: center;

  .work-day {
    margin-left: 7px;
  }

  .work-day-num {
    margin-left: 20px;
    &:hover {
      color: var(--theme-color);
      cursor: pointer;
    }
  }

  .work-day-num-value,
  .work-day-value {
    text-decoration: underline;

    &:hover {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}
.work-day-value-all:hover {
  color: var(--theme-color);
  cursor: pointer;
}
.work-right {
  order: 1;
  flex: 1;

  .iconfont {
    font-size: 20px !important;

    &:hover {
      cursor: pointer;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1600px) {
  .work-day-num {
    display: none;
  }

  .work-day {
    display: none;
  }

  .work-day-num-value {
    margin-left: 15px;
  }
}

/deep/ .search-user-input {
  .ant-input {
    padding-right: 90px !important;
  }
}

.search-user-span:hover {
  cursor: pointer;
  color: var(--theme-color);
}

.search-user:hover {
  cursor: pointer;
  color: var(--theme-color);
}

.search-user,
.search-user-span {
  color: rgba(0, 0, 0, 0.25);
}

/deep/ .search-user svg {
  transform: rotate(0deg) !important;
}

.statistics {
  cursor: pointer;
  width: 100%;
  opacity: 0;
  background: #f5f7fa;

  &:hover {
    opacity: 1;
  }
}

/deep/ .ant-table-footer {
  padding-right: 0px !important;
  background-color: #fff !important;
}

.tabel-result-footer {
  white-space: nowrap;
  padding-left: 44px;
  overflow-x: scroll;

  > div {
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.tabel-result-footer::-webkit-scrollbar {
  height: 0;
}

/* /deep/ .ant-table-header-column {
  .ant-table-selection {
    display: none !important;
  }
} */
/deep/ .ant-badge-count {
  z-index: 10 !important;
}
/deep/ .ant-select-selection-selected-value {
  img {
    display: none;
  }
}

/* /deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
} */
/deep/ .ant-input-number-handler-wrap {
  display: none;
}
</style>
